<template>
  <div>
    <AppFixedPageTitle
      title="Contas a pagar"
      icon="/img/icons/icons8/ios/duration-finance.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isLoading"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="status"
            icon="/img/icons/icons8/ios/progress-indicator_grey.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-11 new-default-gray-font font-weight-400"
              >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.status"
                :items="status"
                :disableBoxShadow="true"
                placeholder="Status"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="row pr-3 mt-1 pl-3">
              <div class="col-12 px-0 mb-2 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Conta Bancária
                </label>
                <puzl-select
                  style="width: 100%"
                  v-model.lazy="filter.bank_account_id"
                  :items="$_bank_accounts"
                  :disableBoxShadow="true"
                  :multiple="true"
                  class="select-sm col-md-12 px-0 new-default-black-font"
                  :loading="loadingBankAccounts"
                  :disabled="loadingBankAccounts"/>
              </div>
              <div class="col-12 px-0 mb-2 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Método de pagamento
                </label>
                <puzl-select
                  style="width: 100%"
                  v-model.lazy="filter.payment_method_ids"
                  :items="$_payment_methods"
                  :disableBoxShadow="true"
                  :multiple="true"
                  class="select-sm col-md-12 px-0 new-default-black-font"
                  :loading="loadingPaymentMethods"
                  :disabled="loadingPaymentMethods"/>
              </div>
              <div class="col-12 px-0 mb-2 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Valor do pagamento
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                             v-money="money"
                             v-model.lazy="filter.valueRange.start"
                             placeholder="de"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                             v-money="money"
                             v-model.lazy="filter.valueRange.end"
                             placeholder="até"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 px-0 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Número do documento
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-custom-group">
                      <input
                        type="text"
                        v-model="filter.documentRange.start"
                        placeholder="de"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-custom-group">
                      <input
                        type="text"
                        v-model="filter.documentRange.end"
                        placeholder="até"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Marcadores"
            icon="/img/icons/icons8/ios/push-pin_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="handleFilterAdvancedStatus(2)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable': filter.advanced === 2}"
              >
                <img
                  alt="Agrupar"
                  height="19"
                  src="/img/icons/icons8/ios/bursts_primary.png"
                  style="margin-right: 4px;"
                >
                Agrupadas
              </button>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            :text="!paid_allotment ? 'Pagt. em lote' : 'Canc. Pagt'"
            :type="!paid_allotment ? 'success' : 'danger'"
            @click.prevent="setPaidAllotments"
            icon="/img/icons/icons8/ios/choose.png"
          />
          <AppPageHeaderActionsButton
            text="Relatórios"
            type="dark"
            :isDropdown="true"
            icon="/img/icons/graph-report.png"
          >
            <AppPageHeaderActionsButtonDropItem
              text="Contas a pagar"
              icon="/img/icons/icons8/ios/duration-finance_danger.png"
              @click.prevent="handleNewBillPayReport"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Despesas"
              icon="/img/icons/excel.png"
              @click.prevent="showModalEntryLaunchExcelReport(false)"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Entradas"
              icon="/img/icons/excel.png"
              @click.prevent="showModalEntryLaunchExcelReport(true)"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Resumo"
              icon="/img/icons/icons8/ios/assignment-turned-in.png"
              @click.prevent="handleModalResume"
            />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>
    <div class="container-fluid">
      <Cards v-show="listType === 'cards'"
        :loading="loading"
        :bills_items="bills_items"
        :listType="listType"
        :paid_allotment="paid_allotment"
        :paid_allotments="paid_allotments"
        :loadingSkeleton="loadingSkeleton"
        @handleEditBillPay="handleEditBillPay"
        @handleAgroup="handleAgroup"
        @handleModalShareInstallment="handleModalShareInstallment"
        @getPaymentAuthorizationProof="getPaymentAuthorizationProof"
        @handleHistory="handleHistory"
        @handleDeleteBillPayInstallment="handleDeleteBillPayInstallment"
        @addSetPaidAllotments="addSetPaidAllotments"
        @handleModalInfo="handleModalInfo"
        @removeAgroup="removeAgroup"
        @handleModalListEntriesLaunches="handleModalListEntriesLaunches"
        @handleModalAttachment="handleModalAttachment"
        @handleEntityAccount="handleEntityAccount"
        @changeInstallmentStatus="changeInstallmentStatus"
        @handleModalBillPayInstallmentAttachment="handleModalBillPayInstallmentAttachment"
      />
      <Table v-show="listType === 'table'"
        :loading="loading"
        :bills_items="bills_items"
        :listType="listType"
        :paid_allotment="paid_allotment"
        :paid_allotments="paid_allotments"
        :loadingSkeleton="loadingSkeleton"
        @handleEditBillPay="handleEditBillPay"
        @handleAgroup="handleAgroup"
        @handleModalShareInstallment="handleModalShareInstallment"
        @getPaymentAuthorizationProof="getPaymentAuthorizationProof"
        @handleHistory="handleHistory"
        @handleDeleteBillPayInstallment="handleDeleteBillPayInstallment"
        @addSetPaidAllotments="addSetPaidAllotments"
        @handleModalInfo="handleModalInfo"
        @removeAgroup="removeAgroup"
        @handleModalListEntriesLaunches="handleModalListEntriesLaunches"
        @handleModalAttachment="handleModalAttachment"
        @handleEntityAccount="handleEntityAccount"
        @changeInstallmentStatus="changeInstallmentStatus"
        @handleModalBillPayInstallmentAttachment="handleModalBillPayInstallmentAttachment"
        @selectAll="selectAll"
      />
      <AppViewTrigger v-if="!isLoading" @onIntersected="listItems(true)"/>
      <PuzlEmptyData v-if="!bills_items.length && !isLoading" />
    </div>
    <div v-show="paid_allotment && paid_allotments.length"
      class="bg-white floating-button">
      <div class="card-with-box-shadow container-fluid">
        <div class="row align-items-center" style="white-space: nowrap;">
          <div class="col-6 pt-2 pb-1" style="border-right: 1px solid #E8E8E8;">
            <h6 class="m-0 new-default-gray-font font-weight-normal">Total Bruto:</h6>
            <h4 class="m-0 new-default-black-font font-weight-500">
              <b class="font-weight-normal h5">R$</b> {{ totalValueSelected | currency('') }}
            </h4>
          </div>
          <div class="col-6 pt-2 pb-1">
            <h6 class="m-0 new-default-gray-font font-weight-normal">Total Líquido:</h6>
            <h4 class="m-0 new-default-black-font font-weight-500">
              <b class="font-weight-normal h5">R$</b> {{ finalValueSelected | currency('') }}
            </h4>
          </div>
        </div>
      </div>
      <base-button @click.prevent="handleOpenModalPaidAllotments()"
        style="border-radius: 0 !important; background: #8bd5af52;"
        :disabled="paid_allotments.length === 0"
        block
        class="border-0 text-success">
        <img class="mr-1" src="/img/icons/icons8/ios/check-dollar_success.png" width="20">
        Finalizar Pagamentos
        <div
          class="ml-1 no-shadow-card-success"
          style="display: inline-flex;">
          <h4
            class="m-0 p-0">
            {{ paid_allotments.length + "/" +  bills_items.length }}
          </h4>
        </div>
      </base-button>
    </div>
    <ModalCreateBillPay ref="modalCreateBillPay"/>
    <ModalEntryLaunchReport ref="modalEntryLaunchReport" />
    <ModalResume ref="modalResume" />
    <ModalEditBillPay ref="modalEditBillPay"/>
    <ModalBillPayDocument ref="modalBillBayDocument"/>
    <ModalBillPayInstallmentHistory ref="modalBillPayInstallmentHistory"/>
    <ModalBillPayAgroup @grouped="listItems" ref="modalBillPayAgroup"/>
    <ModalChangeStatus ref="modalChangeStatus"/>
    <ModalEntityAccount ref="entityAccount"/>
    <modal-paid-allotments @fetch="clearSelecteds()" ref="modalPaidAllotments"/>
    <ModalAttachmentView ref="modalAttachmentView" />
    <ModalInfo ref="modalInfo" />
    <ModalShareInstallment @updated="listItems" ref="modalShareInstallment" />
    <ModalEquipmentApportionmentReport ref="modalEquipmentApportionmentReport" />
    <ModalNewBillPayReport ref="modalNewBillPayReport" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import Cards from "./Shared/_Cards.vue";
import Table from "./Shared/_Table.vue";
import ModalCreateBillPay from "./Shared/_ModalCreate.vue";
import ModalEntryLaunchReport from "./Shared/_ModalEntryLaunchReport";
import ModalEquipmentApportionmentReport from "./Shared/_ModalEquipmentApportionmentReport";
import ModalResume from "./Shared/_ModalResume";
import ModalEditBillPay from "./Shared/_ModalEdit";
import ModalBillPayInstallmentHistory from "./Shared/_ModalHistory";
import ModalBillPayDocument from "./Shared/_ModalDocument";
import ModalBillPayAgroup from "./Shared/_ModalAgroup";
import ModalChangeStatus from "./Shared/_ModalChangeStatus";
import ModalEntityAccount from "@/views/Modules/Configuration/Entity/EntityAccount/Index";
import ModalPaidAllotments from "./Shared/_ModalPaidAllotments";
import ModalInfo from "./Shared/_ModalInfo";
import ModalAttachmentView from "@/views/Modules/Configuration/EntryLaunch/Shared/_ModalAttachmentView";
import ModalShareInstallment from "./Shared/_ModalShareInstallment";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ModalNewBillPayReport from "./Shared/_ModalNewBillPayReport";
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection, AppSelect, AppTabSelect, AppViewTrigger,
  initSearchBarFilterType,
  SearchBarFilterType
} from "@/components/AppGlobal";
import {date, moneyToFloat} from "@/helpers";
import {BillPayListFilterType, initBillPayListFilterType} from "./types/BillPayFilterType";
import {BillReceiveInstallmentEnum} from "@/enum/BillReceiveInstallmentEnum";
import {VMoney} from "v-money";

export default {
  name: "IndexBillPay",
  mixins: [cursorPaginate],
  components: {
    AppSelect, AppTabSelect,
    AppViewTrigger,
    AppPageHeaderActionsButtonDropItem,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppFixedPageTitle,
    AppPageHeader,
    PuzlBreadcrumb,
    Cards,
    ModalCreateBillPay,
    SkeletonPuzlGrid,
    ModalEntryLaunchReport,
    ModalResume,
    ModalEditBillPay,
    ModalPaidAllotments,
    ModalBillPayDocument,
    ModalBillPayInstallmentHistory,
    ModalBillPayAgroup,
    ModalChangeStatus,
    ModalEntityAccount,
    ModalAttachmentView,
    ModalInfo,
    ModalShareInstallment,
    MultiFilter,
    PuzlEmptyData,
    InputDatePicker,
    PuzlSelect,
    Table,
    BaseButtonHoverable,
    ModalEquipmentApportionmentReport,
    ModalNewBillPayReport,
  },
  directives: {
    money: VMoney,
  },
  computed: {
    ...mapGetters({
      bills_items: "billPay/fetch",
      $_bank_accounts: "bankAccount/fetch",
      $_payment_methods: "paymentMethod/fetch",
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: 'Todos',
          selected: this.filter.status.length === 0,
        },
        {
          id: this.BillReceiveInstallmentEnum.RECEIVED,
          name: 'Pagos',
          selected: this.filter.status.includes(1),
        },
        {
          id: this.BillReceiveInstallmentEnum.APPROVED,
          name: 'Aprovados',
          selected: this.filter.status.includes(8),
        },
        {
          id: this.BillReceiveInstallmentEnum.PENDING,
          name: 'Pendentes/Atrasados',
          selected: this.filter.status.includes(0) || this.filter.status.includes(2),
        },
      ];
    },
  },
  data() {
    return {
      listType: 'cards',
      loadingSkeleton: true,
      loadingInstallment: false,
      loadingBankAccounts: true,
      loadingPaymentMethods: true,
      paid_allotment: false,
      paid_allotments: [],
      lowerValue: null,
      highValue: null,
      lowerDocument: null,
      totalValueSelected: 0,
      finalValueSelected: 0,
      highDocument: null,
      selectedAll: false,
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      filter: initBillPayListFilterType(),
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      status: [
        {name: "Pendente", id: 0},
        {name: "Pago", id: 1},
        {name: "Cancelado", id: 3},
        {name: "Serasa", id: 4},
        {name: "Cartório", id: 5},
        {name: "Protestado", id: 6},
        {name: "Jurídico", id: 7},
        {name: "Aprovado", id: 8},
      ],
      orderBy: {
        selected: 0,
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [],
          },
          {
            id: 1,
            name: "CRIAÇÃO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "CRIAÇ. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "bill_pay_installments.created",
              is_desc: true,
            }],
          },
          {
            id: 2,
            name: "CRIAÇÃO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "CRIAÇ. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "bill_pay_installments.created",
              is_desc: false,
            }],
          },
          {
            id: 3,
            name: "VENCIMENTO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "VENC. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "bill_pay_installments.due_date",
              is_desc: true,
            }],
          },
          {
            id: 4,
            name: "VENCIMENTO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "VENC. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "bill_pay_installments.due_date",
              is_desc: false,
            }],
          },
          {
            id: 5,
            name: "VALOR MAIOR PARA O MENOR",
            selected_name: "MAIOR > MENOR",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "bill_pay_installments.total_value",
              is_desc: true,
            }],
          },
          {
            id: 6,
            name: "VALOR MENOR PARA O MAIOR",
            selected_name: "MENOR > MAIOR",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "bill_pay_installments.total_value",
              is_desc: false,
            }],
          },
          {
            id: 7,
            name: "DOCUMENTO MAIOR PARA O MENOR",
            selected_name: "DOC. MAIOR > MENOR",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "bill_pay_installments.document_numbers",
              is_desc: true,
            }],
          },
          {
            id: 8,
            name: "DOCUMENTO MENOR PARA O MAIOR",
            selected_name: "DOC. MENOR > MAIOR",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "bill_pay_installments.document_numbers",
              is_desc: false,
            }],
          },
        ]
      },
      isLoading: true,
      bank_accounts: [],
      bill_pay_status: [
        {text: "Pendente", value: 0},
        {text: "Pago", value: 1},
        {text: "Cancelado", value: 3},
        {text: "Serasa", value: 4},
        {text: "Cartório", value: 5},
        {text: "Protestado", value: 6},
        {text: "Jurídico", value: 7},
        {text: "Aprovado", value: 8},
      ],
      occurrences: [
        {
          label: "Única",
          value: 0,
        },
        {
          label: "Semanal",
          value: 7,
        },
        {
          label: "Mensal",
          value: 30,
        },
        {
          label: "Personalizado",
          value: "8",
        },
      ],
      bill_pay_installment_status: {
        '0': "Pendente",
        '1': 'Pago',
        '2': "Atrasado",
        '3': "Cancelado",
        '4': "Serasa",
        '5': "Cartório",
        '6': "Protestado",
        '7': "Jurídico",
        '8': "Aprovado",
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Pago",
          type: "success",
        },
        0: {
          name: "Pendente",
          type: "warning",
        },
        2: {
          name: "Atrasado",
          type: "danger"
        },
        3: {
          name: "Cancelado",
          type: "danger",
        },
        8: {
          name: "Aprovado",
          type: "approved",
        },
        9: {
          name: "Outros",
          type: "info"
        }
      },
      order_types: [
        { name : 'Padrão', value: 'default'},
        { name : 'A > Z', value: 'entity_name'},
        { name : 'Menor valor', value: 'lower_value'},
        { name : 'Maior valor', value: 'highest_value'}
      ]
    };
  },
  methods: {
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      const { PENDING, OVERDUE } = this.BillReceiveInstallmentEnum;
      const { status } = this.filter;

      const isPending = item.id === PENDING;
      const isAlreadyFiltered = status.includes(item.id);

      if (isAlreadyFiltered) {
        this.filter.status = [];
      } else {
        this.filter.status = [];
        if (isPending) {
          // Adiciona PENDING e OVERDUE
          this.filter.status.push(PENDING, OVERDUE);
        } else if(item.id !== null) {
          // Adiciona o filtro selecionado
          this.filter.status.push(item.id);
        }
      }

      this.listItems();
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      const filterCopy = this.$helper.cloneObject(this.filter);

      // Atualiza filtros principais
      const { searchBarFilter, orderBy } = this;

      filterCopy.company_plant_id = searchBarFilter.company_plant_selected;
      filterCopy.global = searchBarFilter.custom_search_values;
      filterCopy.range.start = searchBarFilter.range.start;
      filterCopy.range.end = searchBarFilter.range.end;

      // Verifica se um valor é vazio ou representa "zero"
      const isEmptyValue = (value) => value === '0,00' || value === '' || !value;

      // Envia range como nulo para evitar erros no backend
      const clearRangeIfEmpty = (range, keysToCheck) => {
        if (!range) return null;

        const isRangeEmpty = keysToCheck.every((key) => isEmptyValue(range[key]));
        return isRangeEmpty ? null : range;
      };

      // Converte valores monetários em float, garantindo que os valores existam
      const convertRangeToFloat = (range, keysToConvert) => {
        if (!range) return null;

        const convertedRange = {};
        keysToConvert.forEach((key) => {
          if (range[key] !== undefined) {
            convertedRange[key] = this.$helper.moneyToFloat(range[key]);
          }
        });

        return convertedRange;
      };

      // Aplica a limpeza nos ranges especificados
      filterCopy.valueRange = clearRangeIfEmpty(filterCopy.valueRange, ['start', 'end']);
      filterCopy.documentRange = clearRangeIfEmpty(filterCopy.documentRange, ['start', 'end']);
      filterCopy.installment_range = clearRangeIfEmpty(filterCopy.installment_range, ['min', 'max']);

      // Converte os ranges não vazios para valores numéricos
      filterCopy.valueRange = convertRangeToFloat(filterCopy.valueRange, ['start', 'end']);
      filterCopy.installment_range = convertRangeToFloat(filterCopy.installment_range, ['min', 'max']);

      // Define ordenação
      const selectedItem = orderBy.items[orderBy.selected];
      const selectedFilter = selectedItem.filter[0] || null;
      filterCopy.order_by = selectedFilter
        ? `${selectedFilter.column}.${selectedFilter.is_desc ? 'desc' : 'asc'}`
        : "default";

      // Define tipo de data
      const dateTypes = ["default", "due_date"];
      filterCopy.date_type = dateTypes[searchBarFilter.range.selected] || "default";

      return filterCopy;
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    listItems(isAccumulateItems = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }

      this.$store
        .dispatch('billPay/fetch',
          {
            filter: this.prepareFilter(),
            next_page: this.filter.next_page
          },
        )
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => {
          this.isLoading = false;
          this.loadingSkeleton = false
        });
    },
    /**
     * Padrão do filtro principal
     * @returns {BillPayListFilterType}
     */
    defaultFilter() {
      return {
        ...initBillPayListFilterType(),
        range: {
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      };
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [
            { id: 0, name: "Padrão", selected_name: "Padrão" },
            { id: 1, name: "Vencimento", selected_name: "Venc." },
          ],
          selected: 0,
          start: this.searchBarFilter.range.start ?? this.currentDate,
          end: this.searchBarFilter.range.end ?? this.currentDate,
        },
      }
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());

      if (isRefreshList) {
        this.listItems();
      }
    },
    verifyIfActive(value)
    {
      // Busca status a partir de string
      const status = Object.entries(this.bill_pay_installment_status).find(([key, val]) => val.toLowerCase() === value.toLowerCase());
      return this.filter.status ? this.filter.status.includes(parseInt(status[0])) : false;
    },
    filterByStatus(value){
      if(this.filter.status.includes(value)){
        this.filter.status = this.filter.status.filter(item => item !== value);
      }
      else {
        this.filter.status.push(value);
      }
      this.listItems();
    },
    handleCreateBillPay() {
      this.$refs.modalCreateBillPay.openModal();
    },
    setViewType(type){
      this.paid_allotments = [];
      this.paid_allotment = false;
      this.selectedAll = false;
      this.listType = type;
    },

    handleNewBillPayReport() {
      this.$refs.modalNewBillPayReport.openModal();
    },
    /**
     * @param {bool} isGroupedByDocument - Relatório de entradas agrupado por documento do emissor da central
     */
    showModalEntryLaunchExcelReport(isGroupedByDocument = false) {
      this.$refs.modalEntryLaunchReport.openModal(isGroupedByDocument);
    },
    handleModalResume() {
      this.$refs.modalResume.openModal();
    },
    setPaidAllotments() {
      this.paid_allotment = !this.paid_allotment
      if (!this.paid_allotment) {
        this.paid_allotments = []
      }
    },
    selectAll(){
      this.selectedAll = !this.selectedAll;

      if(this.selectedAll){
        this.totalValueSelected = 0;
        this.finalValueSelected = 0;
        this.paid_allotments = [];
      }

      this.bills_items.map((bill_item, index) => {
        if(bill_item.status.status !== 1 && bill_item.status.status !== 3){
          if(this.selectedAll) this.addSetPaidAllotments(bill_item.id, index);
          else this.addSetPaidAllotments(bill_item.id);
        }
      });

      if(!this.selectedAll){
        this.totalValueSelected = 0;
        this.finalValueSelected = 0;
        this.paid_allotments = [];
      }
      this.paid_allotment = this.selectedAll;
    },
    verifyPaidAllotments(id, index = false)
    {
      this.addSetPaidAllotments(id, index);

      if(this.paid_allotments.length > 0) this.paid_allotment = true;
      else this.paid_allotment = false;
    },
    handleOpenModalPaidAllotments() {
      this.$refs.modalPaidAllotments.openModal(this.paid_allotments)
    },
    addSetPaidAllotments(id, index = false) {
      this.paid_allotment = true;
      /* Se pagamento já existe no array, será removido */
      if (index === false) {
        index = this.paid_allotments.findIndex(item => item === id);
        const billPay = this.bills_items.find(item => item.id === id);

        this.totalValueSelected -= Number(billPay.total_value);
        this.finalValueSelected -= Number(billPay.final_value);

        this.paid_allotments.splice(index, 1);

        if(!this.paid_allotments.length){
          this.selectedAll = false;
        }
      } else {
        /* Adiciona a array de pagamentos */
        this.totalValueSelected += Number(this.bills_items[index].total_value);
        this.finalValueSelected += Number(this.bills_items[index].final_value);
        this.paid_allotments.push(id)
      }
    },
    clearSelecteds() {
      this.paid_allotments = [];
      this.paid_allotment = false;
      this.totalValueSelected = 0;
      this.finalValueSelected = 0;
      this.listItems();
    },
    getOccurrence(value) {
      switch (value) {
        case 7:
          return "Semanal";
        case 30:
          return "Mensal";
        default:
          return `${value} dias`;
      }
    },
    changeInstallmentStatus(id, status) {
      if (['Aprovado', 'Pago'].includes(this.bill_pay_installment_status[status])) {
        this.$refs.modalPaidAllotments.openModal([id])
      } else {
        this.$refs.modalChangeStatus.openModal(id, status)
      }
    },
    handleDeleteBillPayInstallment(bill_pay) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store.dispatch("billPay/destroy", bill_pay.id).then((response) => {
            this.$notify({type: response.error_type, message: response.message});
            this.$Progress.finish();
          }).catch(error => {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
          });
        }
      });
    },
    handleEditBillPay(bill_pay) {
      const index = this.bills_items.findIndex(item => item.id === bill_pay.id);

      this.$refs.modalEditBillPay.openModal(bill_pay.bill_pay_id, index);
    },
    handleModalListEntriesLaunches(bill_pay_id) {
      this.$refs.modalBillBayDocument.openModal(bill_pay_id);
    },
    handleAdvancedBankAccountFilter() {
      this.listItems();
    },
    handleHistory(bill_pay) {
      this.$refs.modalBillPayInstallmentHistory.openModal(bill_pay.id);
    },
    handleAgroup(bill_pay) {
      this.$refs.modalBillPayAgroup.openModal(bill_pay.bill_pay_id, bill_pay.entity_id);
    },
    handleFilterAdvancedStatus(filter) {
      this.loadingSkeleton = true;
      this.filter.advanced = this.filter.advanced === filter ? null : filter;
      this.listItems();
    },
    handleEntityAccount(entity_id) {
      this.$refs.entityAccount.handleCreateModal(entity_id);
    },

    /**
     * Abre modal de anexos
     *
     * @param {object} item
     * @returns void
     */
    handleModalAttachment(item) {
      this.$refs.modalAttachmentView.openModal(item);
    },

    /**
     * Abre modal de informações
     *
     * @param {object} item
     * @returns void
     */
     handleModalInfo(item) {
      this.$refs.modalInfo.openModal(item);
    },

    /**
     * Retorna ícone de acordo com status
     *
     * @param {string} status
     * @returns string
     */
    getInstallmentStatusIcon(status) {
      switch(status) {
        case 'Aprovado':
          return 'thumb-up--v1-white.png';
        case 'Atrasado':
          return 'present-white.png';
        case 'Cancelado':
          return 'cancel-white.png';
        case 'Pendente':
          return 'hourglass-white.png';
        default:
          return null;
      }
    },

    /**
     * Retorna autorização de pagamento
     *
     * @param {object} bill_pay
     * @returns void
     */
    getPaymentAuthorizationProof(bill_pay) {
      const loader = this.$loading.show();
      this.$store.dispatch('billPay/getPaymentAuthorizationProof',
        bill_pay.id
      ).then(response => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", 'authorization.pdf');
        window.open(link, '_blank');
      }).finally(() => {
        loader.hide();
      });
    },

    /**
     * Abre modal de anexo de parcela
     *
     * @param {object} item
     *
     * @returns void
     */
    handleModalBillPayInstallmentAttachment(item) {
      const attachment = {
        name: item.attachment_name,
        url: item.attachment_url,
        type: item.attachment_type,
        ext: item.attachment_ext,
        size: item.attachment_size,
      };
      this.$refs.modalAttachmentView.openModal(JSON.stringify(attachment));
    },

    /**
     * Remove agrupamento
     *
     * @param {object} bill_pay
     *
     * @returns void
     */
    removeAgroup(bill_pay) {
      const loader = this.$loading.show();
      this.$store.dispatch("billPay/removeAgroup", bill_pay.bill_pay_id)
        .then((response) => {
          this.$notify({type: response.error_type, message: response.message});
          this.listItems();
        })
        .catch(error => {
          this.$notify({
            type: "danger",
            message: error.data.message
        });
      }).finally(() => {
        loader.hide();
      });
    },

    /**
     * Abre modal de divisão de parcela
     *
     * @param {object} item
     * @returns void
     */
     handleModalShareInstallment(item) {
      this.$refs.modalShareInstallment.openModal(item);
    },
    /**
     * Abre modal de relatório para rateio de equipamentos
     *
     * @returns void
     */
     handleModalEquipmentApportionmentReport() {
      this.$refs.modalEquipmentApportionmentReport.openModal();
    },
  },
  mounted() {
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
      this.loadingBankAccounts = false;
    });
    this.$store.dispatch("paymentMethod/fetchItems").then(() => {
      this.loadingPaymentMethods = false;
    });
    this.clearFilter(true);
  },
};
</script>

<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
}

.new-default-gray-font {
  color: #606062 !important;
}

.input-custom-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 30px;
}

.input-custom-group input {
  box-shadow: none !important;
  border: none;
  padding-left: 5px;
}

.input-custom-group:has(> div) input {
  border-left: 1px solid #e96262;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
  padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
  display: inline-flex;
  height: 31px;
  background: white;
  align-items: center;
  font-size: 12px;
}

.input-custom-group div {
  color: #606062;
}

.input-custom-group input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
  outline: none;
}

.active-hoverable {
  background: #1A70B7 !important;
  color: white !important;
}

.active-hoverable img {
  filter: brightness(0) invert(1);
}

.custom-dashboard .el-select__tags-text {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  bottom: 130px;
  right: 38px;
  border-radius: 10px;
  color: #000000;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.card-with-box-shadow {
  border: 1px solid #E8E8E8;
  box-shadow: none;
  border-radius: 10px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  max-width: 310px;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  color: #000000;
}

.floating-button div.card-with-box-shadow {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 0 !important;
}

.no-shadow-card-success {
  border-radius: 6px !important;
  background: transparent;
  padding: 0 5px;
  border: 1px solid #149E57;
}

.no-shadow-card-success h4 {
  color: #149E57;
  padding: 0;
  font-size: 11px;
  letter-spacing: -0.5px;
}
</style>
