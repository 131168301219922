import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import EquipmentCreate from "@/views/Modules/Equipment/Equipment/Shared/_Create";
import EquipmentEdit from "@/views/Modules/Equipment/Equipment/Shared/_Edit";
import EquipmentImplement from "@/views/Modules/Equipment/Equipment/Shared/_ListImplement";
import EquipmentImplementCreate from "@/views/Modules/Equipment/Equipment/Shared/_CreateImplement";
import EquipmentImplementEdit from "@/views/Modules/Equipment/Equipment/Shared/_EditImplement";
import EquipmentCategory from "@/views/Modules/Equipment/Equipment/Category/Index";
import EquipmentType from "@/views/Modules/Equipment/Equipment/Type/Index";
import EquipmentSituation from "@/views/Modules/Equipment/Equipment/Situation/Index";
import Equipment from '../../../src/views/Modules/Equipment/Equipment/routes/EquipmentRoute.js';
import FuelTank from "@/views/Modules/Equipment/FuelControl/FuelTank/Index";
import FuelResume from "@/views/Modules/Equipment/FuelResume/Index";
import FuelControl from "@/views/Modules/Equipment/FuelControl/Index";
import auth from "@/middleware/auth";
import Checklist from '@/views/Modules/Equipment/Checklist/Index';
import ChecklistItem from '@/views/Modules/Equipment/CheckListItem/Index';
import ChecklistMade from '@/views/Modules/Equipment/ChecklistMade/Index';
import ChecklistApproved from '@/views/Modules/Equipment/ChecklistApproved/Index';

const equipment = {
  path: '/equipment',
  component: DashboardLayout,
  name: 'Equipamento',
  meta: {
    permission: 'equipment.dashboard.index.view',
    middleware: auth,
  },
  children: [
    // Start Route Equipment and Implement
    ...Equipment,
    {
      path: '/equipment/equipment/create',
      name: 'equipment.equipment.create',
      component: EquipmentCreate,
      meta: {
        title: 'Equipamento',
        description: 'Novo Equipamento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.equipment.dashboard.index'},
          {title: 'Novo Equipamento', name: null},
        ]
      }
    },
    {
      path: '/equipment/equipment/edit/:equipment_uuid',
      name: 'equipment.equipment.edit',
      component: EquipmentEdit,
      meta: {
        title: 'Equipamento',
        description: 'Editar Equipamento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.equipment.dashboard.index'},
          {title: 'Editar Equipamento', name: null},
        ]
      }
    },
    {
      path: '/equipment/equipment/implements/:equipment_uuid',
      name: 'equipment.implement.index',
      component: EquipmentImplement,
      meta: {
        title: 'Equipamento',
        description: 'Implemento de Equipamentos',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.implement.dashboard.index'},
          {title: 'Implemento de Equipamentos', name: null},
        ]
      }
    },
    {
      path: '/equipment/equipment/implement/create/:equipment_uuid',
      name: 'equipment.implement.create',
      component: EquipmentImplementCreate,
      meta: {
        title: 'Equipamento',
        description: 'Novo Implemento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.implement.dashboard.index'},
          {title: 'Novo Implemento', name: null},
        ]
      }
    },
    {
      path: '/equipment/equipment/implement/edit/:implement_uuid',
      name: 'equipment.implement.edit',
      component: EquipmentImplementEdit,
      meta: {
        title: 'Equipamento',
        description: 'Editar Implemento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.implement.dashboard.index'},
          {title: 'Editar Implemento', name: null},
        ]
      }
    },
    {
      path: '/equipment/equipment/category',
      name: 'equipment.category.index',
      component: EquipmentCategory,
      meta: {
        title: 'Equipamento',
        description: 'Categoria de Equipamento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.category.dashboard.index'},
          {title: 'Categoria de Equipamento', name: null},
        ]
      }
    },
    {
      path: '/equipment/equipment/type',
      name: 'equipment.type.index',
      component: EquipmentType,
      meta: {
        title: 'Equipamento',
        description: 'Tipo de Equipamento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.type.dashboard.index'},
          {title: 'Tipo de Equipamento', name: null},
        ]
      }
    },
    {
      path: '/equipment/equipment/situation',
      name: 'equipment.situation.index',
      component: EquipmentSituation,
      meta: {
        title: 'Equipamento',
        description: 'Situação de Equipamento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.situation.dashboard.index'},
          {title: 'Situação de Equipamento', name: null},
        ]
      }
    },
    {
      path: '/equipment/fuel-control/fuel-tank',
      name: 'equipment.fuel-control.fuel-tank.view',
      component: FuelTank,
      meta: {
        title: 'Equipamento',
        description: 'Reservatório de Combustível',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.fuel-control.fuel-tank.dashboard.index'},
          {title: 'Reservatório de Combustível', name: null},
        ]
      },
    },
    {
      path: '/equipment/fuel-control',
      name: 'equipment.fuel-control.view',
      component: FuelControl,
      meta: {
        global_filter: true,
        title: 'Equipamento',
        description: 'Abastecimento',
        icon: 'fa fa-home',
        permission: 'equipment.fuel-control.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.fuel-control.dashboard.index'},
          {title: 'Abastecimento', name: null},
        ]
      },
    },
    {
      path: '/equipment/fuel-resume',
      name: 'equipment.fuel-resume.view',
      component: FuelResume,
      meta: {
        global_filter: true,
        title: 'Resumo de abastecimento',
        description: 'Resumo de abastecimento',
        icon: 'fa fa-home',
        permission: 'equipment.fuel-resume.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'equipment.fuel-resume.dashboard.index'},
          {title: 'Resumo de abastecimento', name: null},
        ]
      },
    },
    {
      path: "/equipment/checklist",
      name: "equipment.checklist.index.view",
      component: Checklist,
      meta: {
        title: 'Checklist',
        description: 'Checklist',
        permission: 'equipment.checklist.index.view',
        icon: 'fa fa-home',
      },
    },
    {
      path: "/equipment/checklist-item/:uuid",
      name: "equipment.checklist-item.index.view",
      component: ChecklistItem,
      meta: {
        title: 'Itens de Checklist',
        description: 'Itens de Checklist',
        permission: 'financial.finances.index.view',
        icon: 'fa fa-home',
      },
    },
    {
      path: "/equipment/checklist-made",
      name: "equipment.checklist_made.index.view",
      component: ChecklistMade,
      meta: {
        title: 'Checklist realizados',
        description: 'Checklist realizados',
        permission: 'equipment.checklist.index.view',
        icon: 'fa fa-home',
      },
    },
    {
      path: "/equipment/checklist-approved",
      name: "equipment.checklist_approved.index.view",
      component: ChecklistApproved,
      meta: {
        title: 'Checklist aprovados',
        description: 'Checklist aprovados',
        permission: 'equipment.checklist.index.view',
        icon: 'fa fa-home',
      },
    },
    
    // And Route Equipment and Implement
  ]
}

export default equipment;
