import { PagerType, initPagerType } from "../../../../shared/types";
import { ContractProposalType } from "./ContractProposalType";

/**
 * Type para retorno paginado da listagem
 * 
 * @property {int} id
 * @property {0|1} status
 * @property {string} name
 * @property {string} number_without_digit_agency
 * @property {string} digit_agency
 * @property {string} number_without_digit_account
 * @property {string} digit_account
 * @property {string} created_at
 * @property {string} updated_at
 * @property {0|1} pix_status
 * @property {string} bank_code
 * @property {string} created_by_user_name
 * @property {string} updated_by_user_name
 * @property {boolean} has_bank_account_billet_params_integration
 */
export const ContractProposalListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<ContractProposalListType> } )} ContractProposalListPagerType
 */
export const ContractProposalListPagerType = {};

/**
 * Inicializar ContractProposalListPagerType
 * 
 * @returns {ContractProposalListPagerType}
 */
export const initContractProposalListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};


