<template>
  <div>
    <AppFixedPageTitle
      title="Contas a receber"
      icon="/img/icons/icons8/ios/duration-finance.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isLoading"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="status"
            icon="/img/icons/icons8/ios/progress-indicator_grey.png">
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-11 new-default-gray-font font-weight-400"
              >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.status"
                :items="status"
                :disableBoxShadow="true"
                placeholder="Status"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png">
            <div class="row pr-3 mt-1 pl-3">
              <div class="col-12 px-0 mb-2 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Conta Bancária
                </label>
                <puzl-select
                  style="width: 100%"
                  v-model.lazy="filter.bank_account_id"
                  :items="$_bank_accounts"
                  :disableBoxShadow="true"
                  :multiple="true"
                  class="select-sm col-md-12 px-0 new-default-black-font"
                  :loading="loadingBankAccounts"
                  :disabled="loadingBankAccounts"/>
              </div>
              <div class="col-12 px-0 mb-2 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Método de pagamento
                </label>
                <puzl-select
                  style="width: 100%"
                  v-model.lazy="filter.payment_method_ids"
                  :items="$_payment_methods"
                  :disableBoxShadow="true"
                  :multiple="true"
                  class="select-sm col-md-12 px-0 new-default-black-font"
                  :loading="loadingPaymentMethods"
                  :disabled="loadingPaymentMethods"/>
              </div>
              <div class="col-12 px-0 mb-2 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Valor da parcela
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                             v-money="money"
                             v-model.lazy="filter.range_min_value"
                             placeholder="de"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                             v-money="money"
                             v-model.lazy="filter.range_max_value"
                             placeholder="até"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 px-0 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Número da fatura
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-custom-group">
                      <input
                        type="text"
                        v-model="filter.range_min_invoice"
                        placeholder="de"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-custom-group">
                      <input
                        type="text"
                        v-model="filter.range_max_invoice"
                        placeholder="até"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Marcadores"
            icon="/img/icons/icons8/ios/push-pin_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="filter.compensate = Number(!filter.compensate)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable active-hoverable-indigo': filter.compensate}"
              >
                <img
                  alt="Compensação"
                  height="19"
                  src="/img/icons/icons8/ios/us-dollar-circled_indigo.png"
                  style="margin-right: 4px;"
                >
                Compensação
              </button>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="filter.pending = Number(!filter.pending)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable active-hoverable-danger': filter.pending}"
              >
                <img
                  alt="Compensação"
                  height="19"
                  src="/img/icons/icons8/ios/error--v1_danger.png"
                  style="margin-right: 4px;"
                >
                Inadimplência
              </button>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="sendToEmail()"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable': send_to_email}"
              >
                <img
                  alt="E-mail"
                  height="19"
                  src="/img/icons/email-primary.png"
                  style="margin-right: 4px;"
                >
                Envio por e-mail
              </button>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            text="Novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
            @click="handleCreateModal"
          />
          <AppPageHeaderActionsButton
            text="Relatórios"
            type="dark"
            :isDropdown="true"
            icon="/img/icons/graph-report.png">
            <AppPageHeaderActionsButtonDropItem
              text="XLSX"
              icon="/img/icons/excel.png"
              @click.prevent="showModalBillReceiveExcelReport"
            />
            <AppPageHeaderActionsButtonDropItem
              text="PDF"
              icon="/img/icons/pdf-v2.png"
              @click.prevent="handleShowModalReport"
            />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>
    <div class="container-fluid">
      <Cards v-if="listType === 'cards'"
       :listType="listType"
       :bill_receives="bill_receives"
       :send_to_email="send_to_email"
       :send_emails="send_emails"
       :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
       @handleEditBillReceive="handleEditBillReceive"
       @handleShowHistory="handleShowHistory"
       @handleOpenModalInfo="handleOpenModalInfo"
       @handleIdentifyEntity="handleIdentifyEntity"
       @handleModalEntityRegistry="handleModalEntityRegistry"
       @handleDeleteBillReceive="handleDeleteBillReceive"
       @handleListContacts="handleListContacts"
       @handleModalEntityHistory="handleModalEntityHistory"
       @handlePayOffPayment="handlePayOffPayment"
       @showModalEntityPayment="showModalEntityPayment"
       @downloadInvoice="downloadInvoice"
       @nfseButtonClick="nfseButtonClick"
       @downloadBilletBatch="downloadBilletBatch"
       @openModalChangeDueDate="openModalChangeDueDate"
       @showModalEditGenerateBilletHasGenerated="showModalEditGenerateBilletHasGenerated"
       @showModalGenerateBillet="showModalGenerateBillet"
       @showModalGenerateBilletHasGenerated="showModalGenerateBilletHasGenerated"
       @postBatchUpdate="postBatchUpdate"
       @handleShowAttachments="handleShowAttachments"
       @addSendToEmails="addSendToEmails"
       :showCompensationButtons="showCompensationButtons"
       :compareBalance="compareBalance"
       :changeInstallmentStatus="changeInstallmentStatus"
       :formatCurrency="formatCurrency"
       :loadingSkeleton="loadingSkeleton" />
      <Table v-if="listType === 'table'"
        :listType="listType"
        :bill_receives="bill_receives"
        :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
        @handleEditBillReceive="handleEditBillReceive"
        @handleShowHistory="handleShowHistory"
        @handleOpenModalInfo="handleOpenModalInfo"
        @handleIdentifyEntity="handleIdentifyEntity"
        @handleModalEntityRegistry="handleModalEntityRegistry"
        @handleDeleteBillReceive="handleDeleteBillReceive"
        @handleListContacts="handleListContacts"
        @handleModalEntityHistory="handleModalEntityHistory"
        @handlePayOffPayment="handlePayOffPayment"
        @showModalEntityPayment="showModalEntityPayment"
        @downloadInvoice="downloadInvoice"
        @nfseButtonClick="nfseButtonClick"
        @downloadBilletBatch="downloadBilletBatch"
        @openModalChangeDueDate="openModalChangeDueDate"
        @showModalEditGenerateBilletHasGenerated="showModalEditGenerateBilletHasGenerated"
        @showModalGenerateBillet="showModalGenerateBillet"
        @showModalGenerateBilletHasGenerated="showModalGenerateBilletHasGenerated"
        @postBatchUpdate="postBatchUpdate"
        @handleShowAttachments="handleShowAttachments"
        :showCompensationButtons="showCompensationButtons"
        :compareBalance="compareBalance"
        :changeInstallmentStatus="changeInstallmentStatus"
        :formatCurrency="formatCurrency"
        :loadingSkeleton="loadingSkeleton" />
      <AppViewTrigger v-if="!isLoading" @onIntersected="listItems(true)"/>
      <PuzlEmptyData v-if="!bill_receives.length && !isLoading" />
    </div>
    <CreateBillReceive @added="addedBillReceive" ref="modalCreate"/>
    <ModalBankBilletRemittance ref="ModalBankBilletRemittance"
      :show="showModaModalBankBilletRemittance"
      @close="showModaModalBankBilletRemittance = false"/>
    <ModalBankBilletDischarge ref="ModalBankBilletDischarge"
      :show="showModaModalBankBilletDischarge"
      @close="showModaModalBankBilletDischarge = false"/>
    <ModalBillReceiveReport ref="modalBillReceiveReport"/>
    <modal-create-entity @added="addedBillReceive"
      ref="modalCreateEntity"/>
    <modal-report ref="modalReport"/>
    <EditBillReceive @updated="updateBillReceive"
      ref="editBillReceive"/>
    <ModalAttachment ref="modalAttachment"/>
    <ModalHistory ref="ModalHistory"/>
    <ModalAlterStatus ref="ModalAlterStatus"
      :show="showModalAlterStatus"
      @close="closeModalAlterStatus"
      @saveAndClose="saveAndCloseAlterInstallment"/>
    <ModalListContacts ref="listContacts"/>
    <modal-generate-billet ref="modalGenerateBillet"/>
    <modal-generate-billet-has-generated @fetch="listItems()" ref="modalGenerateBilletHasGenerated"/>
    <modal-add-invoice ref="modalAddInvoice"/>
    <modal-search-entity @setEntity="setEntity"
      ref="modalSearchEntity"/>
    <modal-edit-entity @updated="listItems()"
      ref="modalEditEntity"/>
    <modal-info ref="modalInfo"/>
    <modal-edit @showInvoices="listItems()"
      ref="modalEdit"/>
    <modal-change-due-date @updated="listItems()"
      ref="ModalChangeDueDate"/>
    <modal-confirm-payment :return_payment="false"
      @updateInstallment="updateInstallment"
      ref="modalConfirmPayment"
    />
    <ModalEntityHistory @updatedAfterAnticipationBalanceTransfer="updateAfterAnticipationBalanceTransfer"
      ref="modalEntityHistory" />
    <ModalEntityRegistry ref="modalEntityRegistry"
      @storedEntityRegistry="storedAfterEntityRegistration"/>
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection, AppSelect, AppTabSelect, AppViewTrigger,
  initSearchBarFilterType,
  SearchBarFilterType
} from "@/components/AppGlobal";
import Cards from "./Shared/Lists/_Cards";
import Table from "./Shared/Lists/_Table";
import CreateBillReceive from "./Shared/Modals/_Create";
import ModalBankBilletRemittance from "./Shared/Modals/_ModalBankBilletRemittance";
import ModalBankBilletDischarge from "./Shared/Modals/_ModalBankBilletDischarge";
import ModalBillReceiveReport from "./Shared/Modals/_ModalBillReceiveReport";
import ModalSearchEntity from "./Entity/Shared/Modals/_ModalSearchEntity";
import ModalCreateEntity from './Entity/Shared/Modals/_ModalCreateEntity';
import ModalReport from './Shared/Modals/_ModalReport';
import ModalHistory from "./Shared/Modals/_ModalHistory";
import ModalAlterStatus from "./Shared/Modals/_ModalAlterStatus";
import ModalConfirmPayment from "./Shared/Modals/_ModalConfirmPayment";
import ModalGenerateBillet from './Shared/Modals/_ModalGenerateBillet';
import ModalGenerateBilletHasGenerated from './Shared/Modals/_ModalGenerateBilletHasGenerated';
import ModalAddInvoice from '../../Commercial/ContractProposal/Payments/Shared/_ModalAddInvoice';
import ModalListContacts from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalListContacts";
import {mapGetters} from "vuex";
import ModalAttachment from "./Shared/Modals/_ModalAttachment";
import ModalInfo from './Shared/Modals/_ModalInfo.vue';
import ModalChangeDueDate from './Shared/Modals/_ModalChangeDueDate.vue';
import ModalEntityHistory from "@/views/Modules/Configuration/Entity/Shared/_ModalHistory";
import ModalEntityRegistry from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityRegistry";
import ModalEditEntity from './Entity/Shared/Modals/_ModalEditEntity';
import EditBillReceive from "./Shared/Modals/_Edit";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import {base_url_ms, debounce} from '@/plugins';
import {formatErrorValidation} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import Pagination from '@/components/Utils/Pagination'
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import InputDatePicker from '@/components/InputDatePicker';
import {date, moneyToFloat} from "@/helpers";
import {BillReceiveInstallmentEnum} from "@/enum/BillReceiveInstallmentEnum";
import {BillReceiveListFilterType, initBillReceiveListFilterType} from "./types/BillReceiveFilterType";
import ModalEdit from '@/views/Modules/Commercial/ContractProposal/Payments/Shared/_Edit.vue'
import {VMoney} from "v-money";

const PAYMENT_INSTALLMENT_STATUS_ENUM = Object.freeze({
  PENDING: {
    ID: 0,
    NAME: 'Pendente',
  },
  RECEIVED: {
    ID: 1,
    NAME: 'Recebido',
  },
  LATE: {
    ID: 2,
    NAME: 'Atrasado',
  },
  CANCELED: {
    ID: 3,
    NAME: 'Cancelado',
  },
  SERASA: {
    ID: 4,
    NAME: 'Serasa',
  },
  CARTORIO: {
    ID: 5,
    NAME: 'Cartório'
  },
  PROTESTED: {
    ID: 6,
    NAME: 'Protestado'
  },
  LEGAL: {
    ID: 7,
    NAME: 'Jurídico',
  },
  APPROVED: {
    ID: 8,
    NAME: 'Aprovado',
  },
  DENIED: {
    ID: 9,
    NAME: 'Negado'
  },
});

export default {
  name: "IndexBillReceive",
  mixins: [cursorPaginate],
  components: {
    ModalSearchEntity,
    PuzlBreadcrumb,
    AppSelect,
    AppTabSelect,
    AppViewTrigger,
    AppPageHeaderActionsButtonDropItem,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppFixedPageTitle,
    AppPageHeader,
    Cards,
    Table,
    CreateBillReceive,
    ModalBankBilletRemittance,
    ModalBankBilletDischarge,
    ModalBillReceiveReport,
    ModalCreateEntity,
    ModalReport,
    ModalAddInvoice,
    ModalListContacts,
    ModalAttachment,
    ModalSearchEntity,
    ModalInfo,
    EditBillReceive,
    PuzlEmptyData,
    BaseButtonHoverable,
    SkeletonPuzl,
    ModalAlterStatus,
    ModalConfirmPayment,
    LoadingPagination,
    ModalGenerateBillet,
    ModalGenerateBilletHasGenerated,
    ModalHistory,
    EditBillReceive,
    PuzlEmptyData,
    SpinnerPuzl,
    ModalEditEntity,
    PuzlSelect,
    Pagination,
    ModalInfo,
    ModalChangeDueDate,
    ModalEntityHistory,
    ModalEntityRegistry,
    InputDatePicker,
    ModalEdit,
  },
  directives: {
    money: VMoney,
  },
  computed: {
    ...mapGetters({
      bill_receives_settled: "billReceive/fetch",
      $_bank_accounts: "bankAccount/fetch",
      $_payment_methods: "paymentMethod/fetch",
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: 'Todos',
          selected: this.filter.status.length === 0,
        },
        {
          id: this.BillReceiveInstallmentEnum.RECEIVED,
          name: 'Recebidos',
          selected: this.filter.status.includes(1),
        },
        {
          id: this.BillReceiveInstallmentEnum.PENDING,
          name: 'Pendentes/Atrasados',
          selected: this.filter.status.includes(0) || this.filter.status.includes(2),
        },
      ];
    },
  },
  data() {
    return {
      added: false,
      showModaModalBankBilletRemittance: false,
      showModaModalBankBilletDischarge: false,
      loadingBankAccounts: true,
      base_url_ms: base_url_ms(),
      loadingSkeleton: false,
      loadingInstallmentStatus: false,
      isLoading: true,
      hasMore: null,
      listType: 'cards',
      bill_receives: [],
      loadingUsers: true,
      loadingPaymentMethods: true,
      users: [],
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      filter: initBillReceiveListFilterType(),
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      type: 1,
      send_to_email: false,
      params: {
        page: 1,
        per_page: 5,
      },
      disableScrollPaginate: false,
      bill_receive_to_identify: null,
      loadingSkeletonTable: false,
      send_emails: [],
      scrolledCurrent: false,
      loadingSpinner: false,
      loadingDetails: false,
      showModalAlterStatus: false,
      paymentInstallmentStatusEnum: PAYMENT_INSTALLMENT_STATUS_ENUM,
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
      orderBy: {
        selected: 0,
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [],
          },
          {
            id: 1,
            name: "VENCIMENTO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "VENC. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "due_date",
              is_desc: true,
            }],
          },
          {
            id: 2,
            name: "VENCIMENTO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "VENC. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "due_date",
              is_desc: false,
            }],
          },
          {
            id: 3,
            name: "VALOR MAIOR PARA O MENOR",
            selected_name: "MAIOR > MENOR",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "total_value",
              is_desc: true,
            }],
          },
          {
            id: 4,
            name: "VALOR MENOR PARA O MAIOR",
            selected_name: "MENOR > MAIOR",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "total_value",
              is_desc: false,
            }],
          },
        ]
      },
      status: [
        {
          id: 1,
          name: "Recebido"
        },
        {
          id: 2,
          name: "Pendente/Atrasado"
        },
        {
          id: 3,
          name: "Cancelado"
        },
        {
          id: 4,
          name: "Serasa"
        },
        {
          id: 5,
          name: "Cartório"
        },
        {
          id: 6,
          name: "Protestado"
        },
        {
          id: 7,
          name: "Jurídico"
        },
      ],
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        '0': {
          name: "Pendente",
          type: "warning"
        },
        '1': {
          name: "Recebido",
          type: "success"
        },
        '2': {
          name: "Atrasado",
          type: "danger"
        },
        '3': {
          name: "Cancelado",
          type: "danger"
        },
        '8': {
          name: "Aprovado",
          type: "primary"
        },
        '9': {
          name: "Negado",
          type: "danger"
        },
        '10': {
          name: "Outros",
          type: "info"
        }
      },
    };
  },
  watch: {
    added() {
      this.listItems();
    }
  },
  methods: {
    onTabSelectItemClick(item) {
      const { PENDING, OVERDUE } = this.BillReceiveInstallmentEnum;
      const { status } = this.filter;

      const isPending = item.id === PENDING;
      const isAlreadyFiltered = status.includes(item.id);

      if (isAlreadyFiltered) {
        this.filter.status = [];
      } else {
        this.filter.status = [];
        if (isPending) {
          // Adiciona PENDING e OVERDUE
          this.filter.status.push(PENDING, OVERDUE);
        } else if(item.id !== null) {
          // Adiciona o filtro selecionado
          this.filter.status.push(item.id);
        }
      }

      this.listItems();
    },
    showCompensationButtons(bill_receive) {
      return [this.BillReceiveInstallmentEnum.PENDING, this.BillReceiveInstallmentEnum.OVERDUE].includes(bill_receive.status) &&
        (
          (
            bill_receive.type === 1 &&
            this.compareBalance(bill_receive.anticipation_balance, bill_receive.total_value) &&
            this.$hasPermission('bill_receive.receive_installment')
          ) || (
            this.$hasPermission('additional_permissions.financial_approvation') &&
            this.$helper.strToNum(bill_receive.anticipation_balance) > 0
          )
        );
    },
    /**
     * Compara o saldo de antecipação com o valor da fatura e retorna se o saldo é maior ou igual.
     * @param {string} anticipationBalance
     * @param {string} value
     * @returns {boolean}
     */
    compareBalance(anticipationBalance, value) {
      return this.$helper.strToNum(anticipationBalance) >= this.$helper.strToNum(value);
    },
    /**
     * Formata um valor como moeda brasileira
     * @param {string} value
     * @returns {string}
     */
    formatCurrency(value) {
      if (!value) {
        let val = 0
        return val.toString().toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      }
      return this.$helper.strToNum(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
    sendToEmail() {
      this.send_to_email = !this.send_to_email
      if (!this.send_to_email) {
        this.send_emails = []
      }
    },
    handleDeleteBillReceive(id) {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text:
            "Esta exclusão se remete a todas as parcelas desta solicitação, os registros não poderão ser recuperados!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, prosseguir!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("billReceive/destroy", id)
              .then(response => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.fetch();
              })
              .catch(error => {
                if (error.status === 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                }
                this.$Progress.finish();
              });
          }
        });
    },
    /**
     * @param {string} construction_uuid
     */
    handleListContacts(construction_uuid) {
      this.$refs.listContacts.openModal(construction_uuid);
    },
    checkCardIntegration(competence) {
      let loader = this.$loading.show();
      const params = {startAt: competence, endAt: competence};
      this.$store.dispatch("billReceive/checkCardIntegration", params)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
          });
        }).catch((error) => {
        const errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
      }).finally(() => {
        loader.hide();
      });
    },
    /**
     * Abre a modal histórico do cliente
     * @param {number} entity_id
     * @param {string} entity_uuid
     * @param {string} entity_document
     * @param {string} entity_name
     */
    handleModalEntityHistory(entity_id, entity_uuid, entity_document, entity_name) {
      this.$refs.modalEntityHistory.openModal(
        entity_id,
        {
          id: entity_id,
          uuid: entity_uuid,
          document: entity_document,
          entity_name: entity_name,
        },
      );
    },
    /**
     * Abre a modal registros do cliente
     * @param {number} entity_id
     * @param {string} entity_uuid
     * @param {string} name
     * @param {string} document
     */
    handleModalEntityRegistry(entity_id, entity_uuid, name, document) {
      this.$refs.modalEntityRegistry.handleCreateModal(entity_id, entity_uuid, name, document);
    },
    fetchNextPageData() {
      this.scrolledCurrent = true;
      let params = {
        paginate: this.paginate
      };
      this.$store
        .dispatch("billReceive/fetchNextPage", params)
        .then(response => {
          this.hasMore = response.has_more;
          this.loadingSkeleton = false;
          this.scrolledCurrent = false;
          this.loadingSpinner = false;
        });
    },
    async getAttachments(id, type, index) {
      if (!this.bill_receives[index].attachments) {
        this.bill_receives[index].attachments = 1;
        await this.$store
          .dispatch("billReceive/getAttachments", {
            id: id,
            type: type
          })
          .then(response => {
            this.bill_receives[index].attachments = response.data;
            this.$forceUpdate();
          });
      }
    },
    sendSelectedToEmail() {
      this.$Progress.start()
      this.$Swal.confirmActionWithDenied('DESEJA RECEBER O E-MAIL COMO CÓPIA?', ['Sim', 'Não', 'Cancelar'], '', true, true)
        .then((result) => {
          if (result.isConfirmed) {
            this.sendToMail(true)
          }
          if (result.isDenied) {
            this.sendToMail(false)
          }
          if (result.isDismissed) {
            this.$Progress.finish()
          }
        }).catch(() => this.$Progress.finish())
    },
    /**
     * Requisitar atualização de lote de boletos
     * Método utilizado quando lote de boleto é gerado erroneamente
     * @param {*} bank_billet_batch_id
     * @returns {Promise}
     */
    postBatchUpdate(bank_billet_batch_id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/postBatchUpdate', bank_billet_batch_id)
        .then((response) => {
          this.$notify({
            type: "info",
            message: "Requisição para atualizar lote enviada. Aguarde alguns minutos e efetue o download do lote."
          });
          loader.hide()
          this.$Progress.finish()
        }).catch((error) => {
        let errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
        loader.hide();
        this.$Progress.finish();
      })
    },
    downloadBilletBatch(bank_billet_batch_id, last_billet) {
      if (!bank_billet_batch_id) {
        return this.$notify({
          type: 'warning',
          message: 'Lote em processamento. Entre em contato com nosso suporte para mais informações!'
        });
      }
      if (last_billet.status === 0) {
        return this.$notify({
          type: 'warning',
          message: "Boleto em processo de emissão. Aguarde alguns instantes."
        });
      }
      this.$Progress.start()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/getPdfFileUrl', bank_billet_batch_id)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Lote ${bank_billet_batch_id}.pdf`);
          await link.click();
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide()
        });
    },
    showModalGenerateBilletHasGenerated(id, total_index, type) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    showModalEditGenerateBilletHasGenerated(id, total_index, type, index) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive, index)
          loader.hide()
        });
      });
    },
    showModalGenerateBillet(id, total_index, type) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBillet.openModal(id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    downloadInvoice(id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/download', {
          id: id
        }
      ).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    downloadAdvanceReceipt(id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/downloadAdvanceReceipt', {
          entity_bill_receive_id: id
        }
      ).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    saveAndCloseAlterInstallment(params) {
      this.loadingInstallmentStatus = false
      if (params.status === 3) {
        this.listItems();
      }
      this.bill_receives[params.index].status = parseInt(params.status);
      this.showModalAlterStatus = false
    },
    closeModalAlterStatus() {
      this.loadingInstallmentStatus = false
      this.showModalAlterStatus = false
    },
    handleShowHistory(bill_receive) {
      this.$refs.ModalHistory.openModal(bill_receive)
    },
    handleEditBillReceive(payment_uuid, contract_proposal_uuid) {
        this.$refs.modalEdit.handleCreateModal(payment_uuid, contract_proposal_uuid)
    },
    changeInstallmentStatus(id, type, status, index, unique = 0) {
      status = parseInt(status)
      if (this.bill_receives[index].last_billet && this.bill_receives[index].last_billet.status === 1 && status === 1) {
        return this.$Swal.confirmAction('O Boleto já foi emitido para esta parcela, se continuar, o boleto será cancelado.', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show()
              return Promise.all([
                this.$store.dispatch("billReceive/showLight", this.bill_receives[index].id),
                this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {
                  allocated: 1,
                  company_plant_id: this.bill_receives[index].company_plant_id
                })
              ]).then(() => {
                loader.hide()
                return this.$refs.modalConfirmPayment.handleCreateModal(this.bill_receives[index], disabledChangeBankAccount)
              })
            }
          }).catch()
      }
      if (this.loadingInstallmentStatus) {
        return;
      }
      // if (this.bill_receives[index].last_billet.status === 3 && status != 1) {
      //   this.$notify({
      //     type: "warning",
      //     message: "Não foi possível alterar o status. Há uma parcela com pagamento confirmado para este pagamento"
      //   });
      //   return;
      // }
      if (parseInt(status) === 1) {
        let loader = this.$loading.show()
        return Promise.all([
          this.$store.dispatch("billReceive/showLight", this.bill_receives[index].id),
          this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            company_plant_id: this.bill_receives[index].company_plant_id
          })
        ]).then(() => {
          loader.hide()
          const total_value = this.bill_receives[index].total_value
          const disabledChangeBankAccount = (this.bill_receives[index].billets.length > 0);
          return this.$refs.modalConfirmPayment.handleCreateModal(this.bill_receives[index], disabledChangeBankAccount)
        })
      }
      this.loadingInstallmentStatus = true;
      if (unique || ![1, 8, 9].includes(parseInt(status))) {
        this.showModalAlterStatus = true
        return this.$refs.ModalAlterStatus.setBillReceive({
          id: id,
          type: type,
          status: status,
          index: index,
          received_at: this.bill_receives[index].paid_at ? this.bill_receives[index].paid_at : null
        })
      }
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let params = {
        id: id,
        status: status,
        type: type
      };
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", params)
        .then(response => {
          this.bill_receives[index].status = parseInt(status);
          if (status == 1) {
            this.bill_receives[index].paid_at = new Date();
          }
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.listItems();
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          if (error.data.open_modal_edit) {
            const bill_receive = this.bill_receives.find((item) => item.installment_id == id)
            this.handleEditBillReceive(
              bill_receive.id,
              bill_receive.type,
              bill_receive.status
            )
          }
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
        });
    },
    addSendToEmails(id) {
      if (this.send_emails.includes(id)) {
        const index = this.send_emails.findIndex(item => item === id)
        this.send_emails.splice(index, 1)
      } else {
        this.send_emails.push(id)
      }
    },
    async handleOpenAddInvoice(uuid) {
      let loader = this.$loading.show()
      const payment = await this.$store.dispatch('contractProposalPayment/show', uuid).then(response => {
        return response.data
      })
      this.$store.dispatch("billingInvoice/init", {
        filter: {
          pending_payment: 1,
          bill_receive_id: payment.billable_id,
          contract_proposal_id: payment.contract_proposal_id,
          company_plant_id: payment.company_plant_id,
          company_plant_issuer_id: payment.company_plant_issuer_id,
          not_canceled: true,
        }
      }).then(response => {
        const all_invoices = response.data
        let invoices_paid = payment.invoices_paid
        let total_value = 0;
        const invoices = payment.invoices
        invoices.map(function (id) {
          let invoice = all_invoices.find(function (item) {
            return item.id === id;
          });
          const paid_for_this_payment = invoices_paid[invoice.id] || 0
          total_value += Number(invoice.pending_billing) + Number(paid_for_this_payment);
        });
        let invoiced_amount = total_value.toFixed(2);
        let payment_total_value = payment.total_value
          .replace('.', '').replace(',', '')
        let balance = parseFloat(payment_total_value)
          - parseFloat(invoiced_amount.replace('.', '')
            .replace(',', ''))
        if (balance <= 0) {
          loader.hide()
          return this.$notify({
            type: "warning",
            message: "Não há saldo disponível para inserção de novas faturas."
          });
        }
        loader.hide()
        this.$refs.modalAddInvoice.openModal({
          balance: balance,
          initial_balance: balance,
          uuid: uuid,
          bill_receive_id: payment.billable_id,
          invoiced_amount: invoiced_amount,
          contract_proposal_id: payment.contract_proposal_id,
          company_plant_id: payment.company_plant_id,
          company_plant_issuer_id: payment.company_plant_issuer_id,
        })
      })
    },
    /**
     * Identifica depositos não identificados
     * @param bill_receive
     */
    handleIdentifyEntity(bill_receive) {
      this.bill_receive_to_identify = bill_receive
      this.$refs.modalSearchEntity.openModal(false)
    },
    setEntity(entity) {
      let loader = this.$loading.show()
      this.$Progress.start()
      let params = {
        entity_bill_receive_id: this.bill_receive_to_identify.entity_bill_receive_id,
        id: this.bill_receive_to_identify.id,
        entity_id: entity.entity.id
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch('billReceive/postIdentify', params).then(response => {
        loader.hide()
        this.$notify({type: response.error_type, message: response.message});
      }).catch((error) => {
        let errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
      }).finally(() => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    sendToMail(copy_to_user) {
      this.$store.dispatch("billReceive/postSendToEmail", {
        send_emails: this.send_emails,
        copy: copy_to_user,
      }).then(response => {
        this.send_emails = []
        this.send_to_email = false
        return this.$notify({
          type: 'success',
          message: 'Solicitação concluída com sucesso! Este processo será realizado em segundo plano.'
        })
      })
    },
    nfseButtonClick(link) {
      this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
      window.open(link, '_parent');
    },
    async handleShowAttachments(bill_receive, index) {
      let loader = this.$loading.show()
      await this.getAttachments(bill_receive.id, bill_receive.type, index)
      const item = this.bill_receives[index]
      loader.hide()
      this.$refs.modalAttachment.openAttachment(item.attachments)
    },
    async handlePayOffPayment(bill_receive) {
      let loader = this.$loading.show()
      const payment = await this.$store.dispatch('contractProposalPayment/show', bill_receive.payment_uuid).then(response => {
        return response.data
      });
      const entity = await this.$store.dispatch('entity/show', bill_receive.entity_uuid)
        .then(response => response.data)
      loader.hide()
      let use_parcial = null
      let sum_pending = payment.installments.filter((item) => item.status === 0 || item.status === 2)
      sum_pending = sum_pending.reduce((accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue.total_value);
      }, 0);
      if (sum_pending > Number(entity.anticipation_balance)) {
        use_parcial = 1
      }
      let has_balance_to_paid = payment.installments
        .filter((item) => [0, 2].includes(item.status) && Number(item.value) < Number(entity.anticipation_balance))

      if (!has_balance_to_paid) {
        this.$notify({
          type: "danger",
          message: "Não há saldo de antecipação suficiente para recebimento das parcelas."
        });
      }
      if (has_balance_to_paid && !use_parcial) {
        use_parcial = 0
      }
      let text = undefined
      if (use_parcial) {
        text = 'Não há saldo total para recebimento de todas as parcelas, deseja continuar? o recebimento será feito parcialmente'
      } else {
        text = 'Todas as parcelas pendentes serão recebidas, deseja continuar?'
      }
      this.$Swal.confirmAction(`${text}`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('contractProposalPayment/postPayOffPayment', payment.id).then(async response => {
              this.updateBillReceive(bill_receive.id)
              loader.hide()
            }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
              loader.hide()
            })
          }
        })
    },
    handleOpenModalInfo(bill_receive) {
      this.$refs.modalInfo.handleOpenModal(bill_receive)
    },
    async showModalEntityPayment(uuid, installment_id, contract_proposal_uuid) {
      await this.$refs.modalEdit.handleCreateModal(uuid, contract_proposal_uuid);
      const installment = this.$refs.modalEdit.payload.installments.find((item) => item.id === installment_id)
      this.$refs.modalEdit.showModalEntityPayment(installment)
    },
    async updateBillReceive(id) {
      let newBillReceives = this.bill_receives;
      const index = newBillReceives.findIndex(bill_receive => bill_receive.id === id)

      const updated = await this.$store.dispatch('billReceive/showEqualList', id)
      newBillReceives[index].status = updated.data.status;
      newBillReceives[index] = updated.data;

      this.bill_receives = newBillReceives;
    },
    handleFilterOrderBy(type) {
      this.filter.order_by = type
      this.listItems();
    },
    /**
     * Padrão do filtro principal
     * @returns {BillReceiveListFilterType}
     */
    defaultFilter() {
      return {
        ...initBillReceiveListFilterType(),
        range: {
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      };
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [
            { id: 0, name: "Padrão", selected_name: "Padrão" },
            { id: 1, name: "Vencimento", selected_name: "Venc." },
            { id: 2, name: "Vencimento Original", selected_name: "Orig." },
            { id: 3, name: "Data do Crédito", selected_name: "Crédit." },
          ],
          selected: 0,
          start: this.searchBarFilter.range.start ?? this.currentDate,
          end: this.searchBarFilter.range.end ?? this.currentDate,
        },
      }
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter(filterCopy) {
      // Por padrão "canceladas" não são exibidas na tela
      if (!filterCopy.status.length) {
        filterCopy.status = [
          this.paymentInstallmentStatusEnum.RECEIVED.ID,
          this.paymentInstallmentStatusEnum.CARTORIO.ID,
          this.paymentInstallmentStatusEnum.APPROVED.ID,
          this.paymentInstallmentStatusEnum.PROTESTED.ID,
          this.paymentInstallmentStatusEnum.PENDING.ID,
          this.paymentInstallmentStatusEnum.LATE.ID,
          this.paymentInstallmentStatusEnum.SERASA.ID,
          this.paymentInstallmentStatusEnum.DENIED.ID,
          this.paymentInstallmentStatusEnum.LEGAL.ID,
        ];
      } else if (filterCopy.status.includes(this.paymentInstallmentStatusEnum.LATE.ID)) {
        // Atrasadas também são consideradas pendentes
        if (!filterCopy.status.includes(this.paymentInstallmentStatusEnum.PENDING.ID)) {
          filterCopy.status.push(this.paymentInstallmentStatusEnum.PENDING.ID);
        }
      }

      filterCopy.company_plant_id = this.searchBarFilter.company_plant_selected;
      filterCopy.global = this.searchBarFilter.custom_search_values;
      filterCopy.range.start = this.searchBarFilter.range.start;
      filterCopy.range.end = this.searchBarFilter.range.end;
      filterCopy.range_min_value = filterCopy.range_min_value && filterCopy.range_min_value !== '0,00' ? this.$helper.moneyToFloat(filterCopy.range_min_value) : null;
      filterCopy.range_max_value = filterCopy.range_max_value && filterCopy.range_max_value !== '0,00' ? this.$helper.moneyToFloat(filterCopy.range_max_value) : null;

      const selectedItem = this.orderBy.items[this.orderBy.selected];
      const selectedFilter = selectedItem.filter.length ? selectedItem.filter[0] : null;

      filterCopy.order_by = selectedFilter
        ? `${selectedFilter.column}.${selectedFilter.is_desc ? 'desc' : 'asc'}`
        : "";

      const dateTypes = [null ,"due_date", "original_due_date", "paid_at"];
      filterCopy.date_type = dateTypes[this.searchBarFilter.range.selected];

      return filterCopy;
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    listItems(isAccumulateItems = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }

      const filterCopy = this.prepareFilter(this.$helper.cloneObject(this.filter));

      this.$store
        .dispatch('billReceive/fetch',
          {
            filter: filterCopy,
            next_page: this.filter.next_page
          },
        )
        .then((res) => {
          this.bill_receives = this.bill_receives_settled;
          this.resolveCursor(res, this.filter)
        })
        .finally(() => {
          this.isLoading = false;
          this.loadingSkeleton = false
        });
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());

      if (isRefreshList) {
        this.listItems();
      }
    },
    handleViewTypeChange(type){
      this.listType = type;
    },
    openModalChangeDueDate(installment_id, original_due_date){
      this.$refs.ModalChangeDueDate.handleCreateModal(installment_id, original_due_date);
    },
    handleCreateModal() {
      this.$refs.modalCreate.handleCreateModal();
    },
    updateInstallment(installment) {
      const index = this.bill_receives.findIndex(item => item.installment_id === installment.data.installment_id)
      this.bill_receives[index].status = installment.data.status
      this.bill_receives[index].bank_account_name = installment.data.bank_account_name
      this.bill_receives[index].paid_at = installment.data.paid_at
      this.$forceUpdate()
    },
    /**
     * Atualiza total do ícone de registros do cliente após realizar um cadastro.
     * @param {object} entityRegistry
     */
    storedAfterEntityRegistration(entityRegistry) {
      this.$store.dispatch('entity/getTotalRegistriesByEntityId', entityRegistry.entity_id).then(response => {
        let foundEntity = this.bill_receives.find((item) => item.entity_id === entityRegistry.entity_id);
        foundEntity.total_entity_registries = response.data.total_entity_registries;
      });
    },
    /**
     * Atualiza os dados após a transferência de saldo de antecipação entre clientes/fornecedores (entity).
     * @param {number} from_entity_id - ID do cliente/fornecedor de origem.
     * @param {number} to_entity_id - ID do cliente/fornecedor de destino.
     */
    updateAfterAnticipationBalanceTransfer(from_entity_id, to_entity_id) {
      Promise.all([
        this.$store.dispatch('entity/getAnticipationBalanceById', from_entity_id),
        this.$store.dispatch('entity/getAnticipationBalanceById', to_entity_id),
      ]).then((response) => {
        // Atualiza saldo de antecipação dos cards
        const foundBillReceivesFromEntity = this.bill_receives.filter((item) => item.entity_id === from_entity_id);
        foundBillReceivesFromEntity.forEach((billReceive) => {
          billReceive.anticipation_balance = response[0].data;
        });

        const foundBillReceivesToEntity = this.bill_receives.filter((item) => item.entity_id === to_entity_id);
        foundBillReceivesToEntity.forEach((billReceive) => {
          billReceive.anticipation_balance = response[1].data;
        });
      }).catch((error) => {
        this.$refs.modalEntityHistory.closeModal();
        this.listItems();
      });
    },
    addedBillReceive() {
      this.added = !this.added;
    },
    handleShowModalReport() {
      this.$refs.modalReport.openModal()
    },
    showModalBillReceiveExcelReport() {
      this.$refs.modalBillReceiveReport.openModal();
    },
  },
  mounted() {
    this.$store.dispatch("paymentMethod/fetchItems")
      .then((response) => {
        this.loadingPaymentMethods = false;
      });

    this.$store.dispatch("user/fetchItems", {
      filter: {
        is_seller: 1
      }
    }).then((response) => {
      this.users = response.data;
      this.loadingUsers = false;
    });

    this.loadingBankAccounts = true;
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
      this.loadingBankAccounts = false;
    });
    this.loadingSkeleton = true;
    this.clearFilter();
  },
  beforeUpdate() {
    this.$helper.keepGlobalUser(this);
  }
};
</script>

<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
}

.colorize-on-hover:hover {
  color: white !important;
}

.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  min-width: 250px;
  bottom: 95px;
  right: 30px;
  border-radius: 10px;
  color: #000000;
  box-shadow: 0 5px 10px 0 #ccc !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.gray-divider {
  background-color: #E8E8E8;
  opacity: 0.1;
  color: #E8E8E8;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #1b6eba;
  padding: 0px 5px;
  box-shadow: 0 0 2px 0 #0b7bfd52;
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.input-custom-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 30px;
}

.input-custom-group input {
  box-shadow: none !important;
  border: none;
  padding-left: 5px;
}

.input-custom-group input:focus-visible {
  outline: none;
}

.input-custom-group:has(> div) input {
  border-left: 1px solid #e96262;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
  padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
  display: inline-flex;
  height: 31px;
  background: white;
  align-items: center;
  font-size: 12px;
}

.input-custom-group div {
  color: #606062;
}

.input-custom-group input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.active-hoverable {
  background: #1A70B7 !important;
  color: white !important;
}

.active-hoverable-indigo {
  background: rgb(61.0795454545,2.1306818182,122.8693181818) !important;
  color: white !important;
}

.active-hoverable-danger {
  background: #DB4539 !important;
  color: white !important;
}

.active-hoverable img {
  filter: brightness(0) invert(1);
}
</style>
