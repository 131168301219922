<template>
  <div>
    <AppFixedPageTitle
      title="Resumo de cargas"
      icon="/img/icons/ledger-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :rangeParams="{ is_range: false }"
          :showCompanyPlants="true"
          @onSearchClick="init({})"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
              name="Dados"
              icon="/img/icons/icons8/ios/info-squared_gray.png"
            >
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="handleFilterAdvancedStatus"
    >
    
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="init({})"
        placeholder="ORDENAR"
        variant="text-only"
      />
      <a href="#" @click.prevent="downloadChargeResumeExcel('excel')">
        <img
          style="width: 24px"
          src="/img/icons/excel.png"
        />
      </a>
    </AppTabSelect>
    <br>
    <div class="container-fluid">
      <!-- Widgets -->
      <div  class="row row-cols-1 row-cols-md-4 mt-n3">
        <div class="my-2 my-lg-0 col-lg-3">
          <div class="info-view-card success">
            <div>
              <img src="/img/icons/calendar.png" width="30">
            </div>
            <div v-if="loadingWidgets"><SpinnerPuzl/></div>
            <div v-else>
              <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Programado</h5>
              <h3 class="m-0">
                {{ $_widgets.scheduled_volume }} m³ 
                <span style="font-weight: 300 !important; font-size: 12.6px !important;"> 
                  ({{ $_widgets.scheduled_travels }} viagens)
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-lg-0 col-lg-3">
          <div class="info-view-card success">
            <div>
              <img src="/img/icons/ok--v1.png" width="30">
            </div>
            <div v-if="loadingWidgets"><SpinnerPuzl/></div>
            <div v-else>
              <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Realizado</h5>
              <h3 class="m-0 new-default-black-font">
                {{ $_widgets.realized_volume }} m³
              </h3>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-lg-0 col-lg-3">
          <div class="info-view-card light_gray">
            <div>
              <img src="/img/icons/icons8/ios/do-not-disturb-dark.png" width="30">
            </div>
            <div v-if="loadingWidgets"><SpinnerPuzl/></div>
            <div v-else>
              <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Não realizado</h5>
              <h3 class="m-0 new-default-black-font">
                {{ $_widgets.scheduled_volume - $_widgets.realized_volume - $_widgets.canceled_volume }} m³
                <span style="font-weight: 300 !important; font-size: 12.6px !important;"> 
                  ({{ $_widgets.scheduled_travels - $_widgets.realized_travels - $_widgets.canceled_travels }} viagens)
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div class="mb-2 mb-lg-0 col-lg-3">
          <div class="info-view-card danger">
            <div>
              <img src="/img/icons/icons8/ios/cancel_danger.png" width="30">
            </div>
            <div v-if="loadingWidgets"><SpinnerPuzl/></div>
            <div v-else>
              <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Cancelamentos</h5>
              <h3 class="m-0 new-default-black-font">
                {{ $_widgets.canceled_volume }} m³
              </h3>
            </div>
          </div>
        </div>
      </div>
      <br><br>
      <div class="row pt-n1">
        <div class="col-md-12 p-1 mt-3">
          <TableConcretes
            :loadingSkeleton="loadingSkeleton"
            @copyContractProposal="copyContractProposal"
            @downloadProof="downloadProof"
            @downloadDanfe="downloadDanfe"
          />
          <PuzlEmptyData v-if="!$_concretes.length && !loadingSkeleton" />
        </div>
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MultiFilter from "../../../../../components/Utils/MultiFilterV2";
import cursorPaginate from "../../../../../mixins/cursorPaginate";
import LoadingPagination from "../../../../../components/LoadingPagination";
import InputDatePicker from "../../../../../components/InputDatePicker";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData";
import SkeletonPuzl from "../../../../../components/SkeletonPuzl";
import PuzlSelect from "../../../../../components/PuzlSelect";
import { floatToHuman } from "../../../../../helpers";
import moment from "moment";
import ModalAdjustment from "../../../Operational/Schedule/Charging/Shared/_ModalAdjustments.vue";
import TableConcretes from "./_TableConcretes";
import { ScheduleTravelEnum } from "../../../../../enum/ScheduleTravelEnum";
import { XmlTravelEnum } from "../../../../../enum/XmlTravelEnum";
import { ChargeResumeListFilterType, initChargeResumeListFilterType } from "../types";
import {
  AppSearchBar,
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  SearchBarFilterType,
  AppSelect,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
} from "../../../../../components/AppGlobal";
import { date } from "../../../../../helpers";
import SpinnerPuzl from "../../../../../components/SpinnerPuzl.vue";

export default {
  components: {
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlEmptyData,
    SkeletonPuzl,
    PuzlSelect,
    ModalAdjustment,
    TableConcretes,
    AppSearchBar,
    AppFixedPageTitle,
    AppPageHeader,
    AppSearchBarFilterSection,
    ChargeResumeListFilterType,
    AppTabSelect,
    AppSelect,
    SpinnerPuzl,
  },
  name: "ListChargeResume",
  mixins: [cursorPaginate],
  data() {
    return {
      ScheduleTravelEnum: ScheduleTravelEnum,
      XmlTravelEnum: XmlTravelEnum,
      searchBarFilter: initSearchBarFilterType(),
      filter: initChargeResumeListFilterType(),
      currentDate: date.make().format(date.FORMAT.FULL_DATE),
      selectedCompanyPlants: [],
      loadingSkeleton: false,
      loadingConcretes: false,
      loadingWidgets: true,
      loadingCompanyPlants: true,
      floatToHuman: floatToHuman,
      orderBy: {
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [{ 
              column: "schedule_travels.finished_at", 
              is_desc: false,
            }],
          },
          {
            id: 1,
            name: "Mais recente (EMISSÃO)",
            selected_name: "Mais recente (EMISSÃO)",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{ 
              column: "schedule_travels.finished_at", 
              is_desc: true,
            }],
          },
          {
            id: 2,
            name: "Mais antigo (EMISSÃO)",
            selected_name: "Mais antigo (EMISSÃO)",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{ 
              column: "schedule_travels.finished_at", 
              is_desc: false,
            }],
          },
          {
            id: 3,
            name: "A-Z (CLIENTE)",
            selected_name: "A-Z (CLIENTE)",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{ 
              column: "entities.entity_name", 
              is_desc: false,
            }],
          },
          {
            id: 4,
            name: "Z-A (CLIENTE)",
            selected_name: "Z-A (CLIENTE)",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{ 
              column: "entities.entity_name", 
              is_desc: true,
            }],
          },
        ],
        selected: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_widgets: "chargeResume/getWidgets",
      $_concretes: "chargeResume/fetch",
      $_short_plants: "plant/activeShortItems",
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos',
          suffix: this.$_widgets.realized_travels,
          selected: this.filter.canceled !== ScheduleTravelEnum.CANCELED,
        },
        {
          id: ScheduleTravelEnum.CANCELED, 
          name: 'Canceladas',
          suffix: this.$_widgets.canceled_travels,
          selected: this.filter.canceled === ScheduleTravelEnum.CANCELED,
        },
      ];
    },
  },
  async mounted() {
    if (!this.$_short_plants.length) {
      await this.$store.dispatch("plant/fetchItemsActive");
    }
    this.filter.dateFilter = moment().format('YYYY-MM-DD');
    this.fetchItemsPaginate();
    this.clearFilter();
  },
  methods: {
    fetchItemsPaginate() {
      this.init(this.filter);
      this.getWidgets();
    },
    /**
     * @param {object} filter
     */
    init(filter = null) {
      this.$Progress.start();
      this.startCursor(filter);
      this.prepareFilter();
      this.$store
        .dispatch("chargeResume/fetchConcretesItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        })
        .finally(() => {
          this.loadingConcretes = false;
          this.$Progress.finish();
        });
        
        // loadingSkeleton é definido como true apenas durante a filtragem; 
        // em outras operações, como a paginação, permanece false.
        if (this.loadingSkeleton) {
          this.getWidgets();
        }
    },
    getWidgets() {
      this.loadingWidgets = true;
      this.$store.dispatch("chargeResume/getWidgets", { filter: this.filter })
        .then(() => {
          this.loadingWidgets = false;
        });
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.filter.dateFilter = moment(filter).format('YYYY-MM-DD');
      this.startLoading();
      this.init({});
    },
    startLoading() {
      this.loadingConcretes = true;
      this.loadingWidgets = true;
    },
    /**
     * Atualiza o filtro do AppTabSelect somente se o item selecionado for diferente do atual
     * @param {TabSelectItemType} item
     */
    handleFilterAdvancedStatus(selectedItem) {
      if (this.filter.canceled === selectedItem.id) {
        return;
      }
      this.filter.canceled = selectedItem.id;
      this.startLoading();
      this.init({});
    },
    /**
     * @param {string} value
     */
    copyContractProposal(value) {
      navigator.clipboard.writeText(value);
    },
    /**
     * Download o.s de viagens
     * @param {string} schedule_travel_uuid
     */
    downloadProof(schedule_travel_uuid) {
      let params = {
        uuid: schedule_travel_uuid,
        type: "proof",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    /**
     * Download Nf-e
     * @param {number} xml_travel_status
     * @param {string} xml_travel_key
     * @param {string} schedule_travel_uuid
     */
    async downloadDanfe(xml_travel_status, xml_travel_key, schedule_travel_uuid) {
      if (xml_travel_status !== XmlTravelEnum.NFE_COMPLETED) {
        return;
      }
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${xml_travel_key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
     /**
     * Preparar filtro antes da listagem
     */
     prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.dateFilter = moment(this.searchBarFilter.range.start).format('YYYY-MM-DD');
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
     defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        status: null,
        range: {
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.start ?? this.currentDate, // não será utilizado
        },
      };
    },
     /**
     * Padrão do filtro principal
     * @returns {ChargeResumeListFilterType}
     */
     defaultFilter() {
      return {
        ...initChargeResumeListFilterType(),
      };
    },
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      this.orderBy.selected = 0;
      if (isRefreshList) {
        this.init({});
        this.getWidgets();
      }
    },
    /**
     * @param {string} export_type - pdf || excel
     */
    downloadChargeResumeExcel(export_type) {
      const loader = this.$loading.show();
      this.filter.export_type = export_type;
      this.$store
        .dispatch("chargeResume/getReportExcel", this.filter).then(() => {
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        }).catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.font-size-title-widgets {
  font-size: 11.3px;
}
.font-size-widgets {
  font-size: 10px;
}

.info-view-card {
  padding: 16px 16px 16px 16px;
  box-shadow: 0px 5px 10px 0px #0000000D;
  border-radius: 8px; background: white; gap: 16px;
  min-height: 112px;
}
.info-view-card.success {
  background: #F2F7F3;
}
.info-view-card.danger {
  background: #FCF3F3;
}
.info-view-card.light_gray {
  background: #e8e8e8;
}
</style>
