<template>
  <div>
    <modal :show.sync="modal" :backgroundColor="'#E8E8E8'" bodyClasses="p-0">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            src="/img/icons/icons8/ios/order-history_secondary.png"
            alt="history"
            width="24"
          />
          <h5 class="modal-title new-default-black-font m-0 ml-3">
            Histórico de contrato
          </h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          type="link"
          width="auto"
          size="sm"
          :hasOutline="true"
          @click.prevent="closeModal"
        />
      </template>
      <div class="container px-3 py-2">
        <div class="row" v-for="(history, index) in histories" :key="index">
          <!-- Gambiarra pra pegar criacao do contrato ja que ainda nao rastreamos isso, vai ser corrigido em breve -->
          <div
            class="col-2 py-2 mt-1"
            v-if="index === histories.length - 1 && history.status === 1"
          >
            <div
              class="history-vertical-line"
              v-if="index + 1 !== histories.length"
            >
            </div>
            <div class="history-step">
              <div class="history-step-icon">
                <!-- Ícone circulado -->
                <div class="history-circle-icon">
                  <AppIcon
                    icon="briefcase"
                    color="success"
                  />
                </div>
                <!-- Bubble Effect -->
                <div class="history-step-ellipsis">
                  <nav class="circle-1"></nav>
                  <nav class="circle-2"></nav>
                  <nav class="circle-3"></nav>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 py-2 mt-1" v-else>
            <!-- Linha vertical com efeito de Threads -->
            <div
              class="history-vertical-line"
              v-if="index + 1 !== histories.length"
            >
              {{ history.length }}
            </div>
            <div class="history-step">
              <div class="history-step-icon">
                <!-- Ícone circulado -->
                <div class="history-circle-icon">
                  <AppIcon
                    :icon="status[history.status].icon"
                    :color="status[history.status].color"
                  />
                </div>
                <!-- Bubble Effect -->
                <div class="history-step-ellipsis">
                  <nav class="circle-1"></nav>
                  <nav class="circle-2"></nav>
                  <nav class="circle-3"></nav>
                </div>
              </div>
            </div>
          </div>
          <!-- Gambiarra pra pegar criacao do contrato ja que ainda nao rastreamos isso, vai ser corrigido em breve -->
          <div class="col-10"
            v-if="index === histories.length - 1 && history.status === 1"
          >
            <div class="history-step-infos">
              <h4 class="new-default-black-font m-0">
                CRIAÇÃO DO CONTRATO
              </h4>
              <!-- <h5 class="new-default-black-font mb-2" v-if="history?.situation?.name">
              {{ history.situation.name }} 1
            </h5>
            <h5 class="new-default-black-font mb-2" v-if="history?.competitor?.name">
              {{ history.competitor.name }} 2
            </h5>
            <h5 class="new-default-black-font mb-2" v-if="history.reasons.length > 0">
              <span v-for="(item, key) in history.reasons" :key="key">
                {{ item.reason }}
                <span v-if="key != history.reasons.length - 1">|</span>
              </span>
            </h5>
            <h5 class="new-default-black-font mb-2" v-if="history.observation" style="line-height: 1; font-weight: 400 !important;">
              {{ history.observation }}
            </h5> -->
              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ history.user.name }}
              </h6>
              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ history.created_at | parseDate }}
              </h6>
            </div>
          </div>
          <div class="col-10"  v-else>
            <div class="history-step-infos">
              <h4 class="new-default-black-font m-0">
                {{ status[history.status].name }}
              </h4>
              <!-- <h5 class="new-default-black-font mb-2" v-if="history?.situation?.name">
              {{ history.situation.name }} 1
            </h5>
            <h5 class="new-default-black-font mb-2" v-if="history?.competitor?.name">
              {{ history.competitor.name }} 2
            </h5>
            <h5 class="new-default-black-font mb-2" v-if="history.reasons.length > 0">
              <span v-for="(item, key) in history.reasons" :key="key">
                {{ item.reason }}
                <span v-if="key != history.reasons.length - 1">|</span>
              </span>
            </h5>
            <h5 class="new-default-black-font mb-2" v-if="history.observation" style="line-height: 1; font-weight: 400 !important;">
              {{ history.observation }}
            </h5> -->
              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ history.user.name }}
              </h6>
              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ history.created_at | parseDate }}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-3" />
      <div class="container px-4 mb-3">
        <div class="row">
          <div class="offset-8 col-4 px-2">
            <AppButton
              label="Fechar"
              type="dark"
              :hasOutline="true"
              :isBlock="true"
              @click.prevent="closeModal"
            />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { AppButton, AppIcon } from "../../../../../components/AppGlobal";
import { ContractProposalEnum } from "@/enum/ContractProposalEnum";
export default {
  components: {
    AppButton,
    AppIcon,
  },
  name: "ModalContractProposalApprovalHistory",
  data() {
    return {
      modal: false,
      validated: false,
      loading: false,
      histories: [],
      status: {
        [ContractProposalEnum.INACTIVE]: {
          name: "CONTRATO INATIVADO",
          icon: "shutdown",
          color: "danger",
        },
        [ContractProposalEnum.ACTIVE]: {
          name: "CONTRATO REATIVADO",
          icon: "check",
          color: "success",
        },
        [ContractProposalEnum.PENDING_REVIEW]: {
          name: "Pendência Revisão",
          icon: "hourglass",
          color: "warning",
        },
        [ContractProposalEnum.PENDING_APPROVAL]: {
          name: "Pendência Aprovação",
          icon: "hourglass",
          color: "warning",
        },
        [ContractProposalEnum.PROPOSAL]: {
          name: "Criaçāo de Proposta",
          icon: "briefcase",
          color: "success",
        },
        [ContractProposalEnum.COMPLETED]: {
          name: "Contrato Concluído",
          icon: "briefcase",
          color: "success",
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(histories) {
      console.log(histories);

      this.histories = histories;
      this.modal = true;
    },
    save() {},
  },
  created() {},
  mounted() {},
};
</script>

<style>
.border-3 {
  border-width: 3px !important;
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.history-vertical-line {
  background: #e8e8e8;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 29px;
  top: 38px;
}

.history-step-icon {
  display: inline-flex;
  align-items: center;
}

.history-circle-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
}

.history-step-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.history-step-ellipsis nav {
  border: 1px solid #828282;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
}

.circle-1 {
  width: 8px;
  height: 8px;
}

.circle-2 {
  width: 4px;
  height: 4px;
}

.circle-3 {
  width: 2px;
  height: 2px;
}

.history-step-infos {
  position: relative;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 0;
}

@media (max-width: 767px) {
  .nfe-spot {
    right: 10px;
    bottom: 10px;
  }
}

@media (min-width: 768px) {
  .nfe-spot {
    right: 10px;
    top: 10px;
  }
}

.nfe-spot {
  position: absolute;
  display: flex;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  height: 26px;
  min-width: 125px;
  text-align: center;
  box-shadow: 0px 5px 10px 0px #0000000d;
}

.nfe-spot div {
  flex: auto;
  gap: 5px;
}

.nfe-spot div img,
.nfe-spot div h5 {
  display: inline-flex;
  margin-left: 7px;
  margin-right: 7px;
}

.nfe-spot div h5 {
  font-size: 12px;
  color: #2b2d32;
}

.nfe-spot div:hover img,
.nfe-spot div:hover h5 {
  filter: brightness(0) invert(1);
  color: white !important;
}

.nfe-spot.danger:hover {
  background-color: #db4539;
  border-color: #db4539;
}

.nfe-spot.warning:hover {
  background-color: #f2b532;
  border-color: #f2b532;
}

.nfe-spot.primary:hover {
  background-color: #1a70b7;
  border-color: #1a70b7;
}

.nfe-spot.success:hover {
  background-color: #149e57;
  border-color: #149e57;
}
</style>