export const AppIconVariants = Object.freeze({
  defaultVariants: Object.freeze({
    color: "dark",
    icon: "default.svg",
  }),
  variants: Object.freeze({
    color: Object.freeze({
      dark: "dark",
      white: "white",
      primary: "primary",
      success: "success",
      warning: "warning",
      danger: "danger",
      muted: "muted",
      gray: "gray",
    }),
    svg: Object.freeze({
      'add-new': 'add-new.svg',
      agreement: 'agreement.svg',
      attach: 'attach.svg',
      autograph: "autograph.svg",
      automation: "automation.svg",
      'bar-chart': 'bar-chart.svg',
      barcode: 'barcode.svg',
      briefcase: "briefcase.svg",
      calendar: "calendar.svg",
      cancel: "cancel.svg",
      'card-payment': "card-payment.svg",
      check: "check.svg",
      "chemical-plant": "chemical-plant.svg",
      "chemical-plant-3": "chemical-plant-3.svg",
      cheque: "cheque.svg",
      clock: 'clock.svg',
      close: "close.svg",
      'closed-eye': "closed-eye.svg",
      modal_close: "modal_close.svg",
      'double-tick': "double-tick.svg",
      down: 'down.svg',
      "contact-details": "contact-details.svg",
      contact: "contact.svg",
      create: "create.svg",
      email: "email.svg",
      erro: "erro.svg",
      exclamation: "exclamation.svg",
      "external-link-squared": "external-link-squared.svg",
      eye: "eye.svg",
      filter: "filter.svg",
      hourglass: "hourglass.svg",
      "info-squared": "info-squared.svg",
      info: "info.svg",
      "insurance-agent": "insurance-agent.svg",
      keypad: "keypad.svg",
      linkedin: "linkedin.svg",
      "male-user": "male-user.svg",
      "merchant-account": "merchant-account.svg",
      "order-history": "order-history.svg",
      padlock: "padlock.svg",
      phone: "phone.svg",
      pix: 'pix.svg',
      "plus-math": "plus-math.svg",
      printer: "printer.svg",
      'profit-report': "profit-report.svg",
      "progress-indicator": "progress-indicator.svg",
      ratings: "ratings.svg",
      restart: "restart.svg",
      save: "save.svg",
      search: "search.svg",
      "security-shield": "security-shield.svg",
      settings: "settings.svg",
      shutdown: "shutdown.svg",
      'test-tube': "test-tube.svg",
      'thumbs-down': 'thumbs-down.svg',
      'thumbs-up': 'thumbs-up.svg',
      'todo-list': 'todo-list.svg',
      'trash-can': "trash-can.svg",
      wallet: "wallet.svg",
      "numeric-selection": "numeric.svg",
      website: 'website.svg',
    }),
    png: Object.freeze({
      default: "/pngs/default.svg.png", //Deixei so pra caso tenha que fazer com PNG tambem
    }),
  }),
});
