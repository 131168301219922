<template>
  <div
    class="row card-wrapper"
    v-if="bills_items.length && !loadingSkeleton">
    <div class="col-lg-4"
       v-for="(item, index) in bills_items"
       :key="index">
        <div
          class="card main-card mb-3">
          <div class="p-3 px-4">
            <div
              class="row align-items-center mt-2">
              <div
                class="col-auto">
                <BillPayPossibleActions
                  @changeInstallmentStatus="changeInstallmentStatus"
                  :listType="listType"
                  :bill_pay="item"
                />
              </div>
              <div
                class="col px-0">
                <el-popover trigger="click" placement="bottom">
                  <span
                    style="width: 350px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row">
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;">
                      <AppIcon
                        icon="chemical-plant"
                        width="25"
                      />
                      <h4
                        style="font-size: 14px;"
                        class="p-0 ml-2 m-0 font-weight-500 new-default-black-font">
                        Central:
                      </h4>
                    </div>
                    <div class="col-12 mb-2">
                      <h4 class="m-0 new-default-black-font font-weight-normal">
                        {{ item.company_plant_name }}
                      </h4>
                      <h4 class="m-0 new-default-black-font font-weight-normal">
                        {{ item.buyer_ein	}}
                      </h4>
                    </div>
                    <div
                      class="col-12 mb-2 px-0">
                      <hr class="gray-divider m-0">
                    </div>
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;">
                      <img
                        src="/img/icons/icons8/ios/transaction-approved_danger.png"
                        width="25"
                      />
                      <h4
                        style="font-size: 14px;"
                        class="p-0 ml-2 m-0 font-weight-500 new-default-black-font">
                        Dados da parcela:
                      </h4>
                    </div>
                    <div class="container">
                      <div class="row align-items-center mb-2">
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            Data do vencimento
                          </h4>
                        </div>
                        <div class="col px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            {{ $helper.parseDate(item.due_date, 'DD MMM YYYY') }}
                          </h4>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            Vencimento Original
                          </h4>
                        </div>
                        <div class="col px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            {{ $helper.parseDate(item.original_due_date, 'DD MMM YYYY') }}
                          </h4>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2" v-if="item.paid_at">
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            Data de pagamento
                          </h4>
                        </div>
                        <div class="col px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            {{ item.paid_at }}
                          </h4>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            Valor
                          </h4>
                        </div>
                        <div class="col px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            {{ $helper.toMoney(item.total_value) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            Desconto
                          </h4>
                        </div>
                        <div class="col px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            {{ $helper.toMoney(item.discounts_fees) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            Juros/Multa
                          </h4>
                        </div>
                        <div class="col px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto">
                          <h4 class="new-default-black-font m-0 font-weight-normal">
                            {{ $helper.toMoney(item.fees_fines) }}
                          </h4>
                        </div>
                      </div>
                      <div class="row align-items-center mx-0 mb-2 py-1" style="background: #FCF3F3; border-radius: 4px;">
                        <div class="col-auto pl-1">
                          <h4 class="new-default-black-font m-0">
                            Valor Final
                          </h4>
                        </div>
                        <div class="col px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div class="col-auto pr-1">
                          <h4 class="new-default-black-font m-0">
                            {{ $helper.toMoney(item.final_value) }}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </span>
                  <base-button
                    slot="reference"
                    outline
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 mr-2 shadow-none text-indigo"
                  >
                    <img
                      src="/img/icons/info.png"
                      width="25"
                    >
                  </base-button>
                </el-popover>
                <el-popover
                  trigger="click"
                  placement="bottom"
                >
                  <span
                    style="width: 190px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row">
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;">
                      <h4
                        style="font-size: 14px;"
                        class="p-0 m-0 font-weight-500 new-default-black-font">
                        Conta Bancária
                      </h4>
                    </div>
                    <div
                      class="col-12 mb-2 px-0">
                      <hr class="gray-divider m-0">
                    </div>
                    <div
                      class="col-12">
                      <h5
                        class="font-weight-normal new-default-black-font m-0 mb-1">
                        {{ item.bank_account_name }}
                      </h5>
                    </div>
                  </span>
                  <base-button
                    outline
                    slot="reference"
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 mr-2 shadow-none text-indigo"
                  >
                    <img
                      src="/img/icons/merchant-account.png"
                      width="25"
                    >
                  </base-button>
                </el-popover>
                <el-popover
                  trigger="click"
                  placement="bottom"
                  v-if="item.document_observations"
                >
                  <span
                    style="width: 270px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row">
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;">
                      <h4
                        style="font-size: 14px;"
                        class="p-0 m-0 font-weight-500 new-default-black-font">
                        Observações
                      </h4>
                    </div>
                    <div
                      class="col-12 mb-2 px-0">
                      <hr class="gray-divider m-0">
                    </div>
                    <div
                      class="col-12">
                      <h5
                        class="font-weight-normal new-default-gray-font m-0 mb-1">
                        {{ item.document_observations }}
                      </h5>
                    </div>
                  </span>
                  <base-button
                    outline
                    slot="reference"
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 mr-2 shadow-none text-indigo"
                  >
                    <img
                      src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png"
                      width="25"
                    >
                  </base-button>
                </el-popover>
                <base-button
                  v-if="item.document_photo"
                  @click.prevent="$emit('handleModalAttachment', item.document_photo)"
                  outline
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 mr-2 shadow-none text-indigo">
                  <img
                    src="/img/icons/attach-green.png"
                    width="25"
                  >
                </base-button>
                <base-button
                  v-show="item.payment_method_id && [1, 4].includes(item.payment_method_id)"
                  @click.prevent="$emit('handleEntityAccount', item.entity_id)"
                  outline
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 mr-2 shadow-none text-indigo"
                >
                  <img
                    src="/img/icons/bank-building-red.png"
                    width="25"
                  >
                </base-button>
              </div>
              <img
                :src="paid_allotments.includes(item.id)
                  ? '/img/icons/icons8/ios/checked-checkbox.png'
                  : '/img/icons/icons8/ios/checked-checkbox--v1.png'"
                style="position: absolute; right: 20px;"
                class="pointer"
                width="32"
                v-if="paid_allotment && (item.status.status !== BillReceiveInstallmentEnum.RECEIVED && item.status.status !== BillReceiveInstallmentEnum.CANCELED)"
                @click.prevent="paid_allotments.includes(item.id) ? $emit('addSetPaidAllotments', item.id) :  $emit('addSetPaidAllotments', item.id, index)"
              >
              <SettingOptions v-if="!paid_allotment"
                :bill_pay="item"
                :listType="listType"
                @handleEditBillPay="handleEditBillPay"
                @handleModalShareInstallment="handleModalShareInstallment"
                @handleAgroup="handleAgroup"
                @removeAgroup="removeAgroup"
                @getPaymentAuthorizationProof="getPaymentAuthorizationProof"
                @handleHistory="handleHistory"
                @handleDeleteBillPayInstallment="handleDeleteBillPayInstallment"
              />
            </div>
            <div
              class="row mt-3 align-items-end">
              <div
                class="col-auto"
              >
                <h5
                  class="font-weight-normal new-default-black-font m-0 mt-1"
                  v-if="item.payment_method_name"
                >
                  {{ item.payment_method_name }}
                </h5>
                <div
                  style="display: flex;align-items: baseline;"
                  slot="reference">
                  <p
                    style="font-size: 16px;"
                    class="m-0 new-default-black-font font-weight-bold p-0 mr-1"
                  >
                    R$
                  </p>
                  <h1
                    class="m-0"
                  >
                    {{ $helper.toMoneyWithoutCurrencyLayout(item.total_value) }}
                  </h1>
                </div>
                <h5
                  class="new-default-gray-font font-weight-normal m-0"
                  style="display: flex;align-items: center;"
                >
                  <template v-if="item.paid_at">
                    {{ item.paid_at }}
                  </template>
                  <template v-else>
                    {{ $helper.parseDate(item.due_date, 'DD MMM YYYY') }}
                  </template>
                  <div
                    class="ml-3 no-shadow-card-blue"
                    style="display: inline-flex;">
                    <h4
                      class="m-0 p-0"
                    >
                      {{ item.index + "/" + item.last_index }}
                    </h4>
                  </div>
                </h5>
              </div>
            </div>
            <hr class="gray-divider mt-3 mb-3">
            <div
              class="row align-items-center">
              <div
                class="col-12">
                <h4 class="m-0 font-weight-400 new-default-gray-font">
                  {{ item.type_name }}
                </h4>
                <el-popover
                  trigger="click"
                  placement="top"
                >
                  <span
                    style="width: 230px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row"
                  >
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;"
                    >
                      <h4
                        style="font-size: 14px;"
                        class="p-0 m-0 font-weight-500 new-default-black-font"
                      >
                        CPF/CNPJ
                      </h4>
                    </div>
                    <div
                      class="col-12 mb-2 px-0"
                    >
                      <hr class="gray-divider m-0">
                    </div>
                    <div
                      class="col-12"
                    >
                      <h3
                        class="m-0 mb-1 font-weight-400 new-default-black-font"
                      >
                        {{ item.entity_document }}
                      </h3>
                    </div>
                  </span>
                  <h3
                    slot="reference"
                    class="m-0 font-weight-400 new-default-black-font pointer"
                    style="width: fit-content;"
                  >
                    {{ truncateText(item.entity_name, 53) }}
                  </h3>
                </el-popover>
              </div>
            </div>
            <div
              class="row px-2 mt-2 mb-1 align-items-center">
              <div
                v-if="item.document_quantity > 0"
                class="col-4 p-1 px-2">
                <a
                  @click.prevent="$emit('handleModalListEntriesLaunches', item.bill_pay_id)"
                  class="new-cardwithbox-shadow"
                  href="#"
                  style="display: inherit;height: 30px;">
                  <div
                    class="new-default-black-font"
                    style="font-size: 12px; display: block;">
                    <img
                      src="/img/icons/icons8/ios/bill_primary.png"
                      class="mr-1"
                      width="18">
                    {{ item.document_quantity > 1 ?  `Agrupadas (${item.document_quantity })` : item.document_numbers}}
                  </div>
                </a>
              </div>
              <div
                v-if="item.attachment_url"
                class="col-4 p-1 px-2">
                <a
                  @click.prevent="$emit('handleModalBillPayInstallmentAttachment', item)"
                  class="new-cardwithbox-shadow"
                  href="#"
                  style="display: inherit;height: 30px;">
                  <div
                    class="new-default-black-font"
                    style="font-size: 12px; display: block;">
                    <img
                      src="/img/icons/attach-green.png"
                      class="mr-1"
                      width="18">
                    Anexos
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div
      class="row card-wrapper"
      v-show="loadingSkeleton">
      <SkeletonPuzlGrid
        v-for="(index) in 3"
        :key="index">
      </SkeletonPuzlGrid>
    </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import LoadingPagination from "@/components/LoadingPagination";
import SettingOptions from "./Common/SettingOptions.vue";
import { BillReceiveInstallmentEnum } from "@/enum/BillReceiveInstallmentEnum";
import BillPayPossibleActions from "@/views/Modules/Financial/BillPay/Shared/Common/BillPayPossibleActions.vue";
import {AppIcon} from "@/components/AppGlobal";

export default {
  name: "CardsBillPay",
  computed: {
    BillReceiveTypeEnum() {
      return BillReceiveTypeEnum
    }
  },
  props: {
    loading: Boolean,
    bills_items: Array,
    listType: String,
    paid_allotment: Boolean,
    paid_allotments: Array,
    loadingSkeleton: Boolean,
    loadingInstallment: Boolean,
  },
  components: {
    AppIcon,
    BillPayPossibleActions,
    SettingOptions,
    LoadingPagination,
    SkeletonPuzlGrid
  },
  data() {
    return {
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
    };
  },
  methods: {
    truncateText(text, limit = 10){
      return text.length > limit ? text.slice(0, limit) + "..." : text;
    },
    changeInstallmentStatus(bill_pay_id, status_value){
      this.$emit('changeInstallmentStatus', bill_pay_id, status_value)
    },
    handleEditBillPay(bill_pay){
      this.$emit('handleEditBillPay', bill_pay)
    },
    handleModalShareInstallment(bill_pay){
      this.$emit('handleModalShareInstallment', bill_pay)
    },
    handleAgroup(bill_pay){
      this.$emit('handleAgroup', bill_pay)
    },
    removeAgroup(bill_pay){
      this.$emit('removeAgroup', bill_pay)
    },
    getPaymentAuthorizationProof(bill_pay){
      this.$emit('getPaymentAuthorizationProof', bill_pay)
    },
    handleHistory(bill_pay){
      this.$emit('handleHistory', bill_pay)
    },
    handleDeleteBillPayInstallment(bill_pay){
      this.$emit('handleDeleteBillPayInstallment', bill_pay)
    }
  }
};
</script>

<style scoped>
.card {
  box-shadow: none !important;
  border: 1px solid #E8E8E8;
  border-radius: 8px !important;
}

.main-icon-menu {
  min-width: 18px;
}

.custom-border-radius .fa-bars {
  border-radius: 1.375rem !important;
}

.paid-allotment-float {
  position: fixed;
  bottom: 150px;
  right: 40px;
  background-color: #149e57 !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-cardwithbox-shadow div {
  display: inline-flex;
  align-items: center;
  /*  padding-top: 4px;*/
}

.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #7498C7;
  padding: 0px 5px;
  /* box-shadow: 0 0 2px 0 #0b7bfd52; */
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4 {
  font-size: 12px;
}

.new-minicard-green {
 background-color: #F2F7F3;
 border-radius: 15px;
 padding: 5px 12px;
 font-size: 12px;
 /*  box-shadow: 0 0 5px 0 #149e5752;*/
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
  /*  box-shadow: 0 0 5px 0 #d2272752;*/
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
  /*  box-shadow: 0 0 5px 0 #fdef0b52;*/
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
  /*  box-shadow: 0 0 5px 0 #0b7bfd52;*/
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
  /*  box-shadow: 0 0 5px 0 #5d636a52;*/
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-table {
  display: block;
}

.new-minicard-table div {
  display: inline-flex;
  align-items: center;
  margin-top: 1px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>
