<template>
  <div>
    <AppFixedPageTitle
      title="Centro de Custos"
      icon="/img/icons/icons8/ios/icons8-gear-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :isLoading="isListing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
          <AppPageHeaderActionsButton
            @click="openCostCenterCreateModal"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid">
      <CostCenterListPageCards
        @onEditItemClick="openCostCenterEditModal"
        @onRemoveItemClick="removeCostCenter"
      />
      <AppViewTrigger v-if="!isListing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!isListing && !listed.items.length"/>
    </div>
    <CostCenterCreateModal ref="costCenterCreateModal" />
    <CostCenterEditModal ref="costCenterEditModal" />
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import costCenterStore from '../../store/costCenterStore';
import { initCostCenterListFilterType, CostCenterListFilterType } from '../../types';
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import { dialogs } from '../../../../../helpers';
import CostCenterListPageCards from './CostCenterListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import CostCenterCreateModal from "../create/CostCenterCreateModal.vue";
import CostCenterEditModal from '../edit/CostCenterEditModal.vue';
import { Ref } from 'vue';
import { CostCenterCreateModalExpose } from '../create/CostCenterCreateModal.vue';
import { CostCenterEditModalExpose } from '../edit/CostCenterEditModal.vue';
//#endregion

//#region ComponentRefs
/** @type {Ref<CostCenterCreateModalExpose>} */
const costCenterCreateModal = ref(null);

/** @type {Ref<CostCenterEditModalExpose>} */
const costCenterEditModal = ref(null);
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {CostCenterListFilterType} - Filtro principal */
const filter = reactive(initCostCenterListFilterType());

const listType = ref('cards');

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [{
        column: "cost_centers.id",
        is_desc: false,
      }],
    },
    {
      id: 1,
      name: "DO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "cost_centers.created_at",
        is_desc: true,
      }],
    },
    {
      id: 2,
      name: "DO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "cost_centers.created_at",
        is_desc: false,
      }],
    },
  ],
  selected: 0,
});
//#endregion

//#region Computeds
/** Store Getters */
const listed = computed(() => costCenterStore.getters.getListed());
const isListing = computed(() => costCenterStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: true,
      name: 'Ativo',
      selected: filter.status === true,
    },
    {
      id: false,
      name: 'Inativo',
      selected: filter.status === false,
    },
  ];
});
//#endregion

//#region Methods
/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? costCenterStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }
  
  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
};

/**
 * Padrão do filtro principal
 * @returns {CostCenterListFilterType}
 */
function defaultFilter() {
  return initCostCenterListFilterType();
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {  
  prepareFilter();
  costCenterStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
};

/** 
 * Abrir modal para criar registro
 */
function openCostCenterCreateModal() {
  costCenterCreateModal.value.openModal();
}

/**
 * Abrir modal para editar registro
 * @param {number} id
 */
 function openCostCenterEditModal(id) {
  costCenterEditModal.value.openModal(id);
};

  /**
   * Remover registro
   * @param {number} id
   */
  async function removeCostCenter(id) {
    const isConfirmed = await dialogs.confirmRemove();
    if (isConfirmed) {
      await costCenterStore.actions.remove(id);
      dialogs.notifyRemoved();
    }
  };
  
  /**
   * Atualizar status do registro
   * @param {0|1|null} status
   * @param {number} id
   */
  async function updateStatusCostCenter(status, id) {
    await costCenterStore.actions.updateStatus(status, id);
    dialogs.notify();
  };
  //#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  costCenterStore.mutations.resetStates();
  costCenterStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>

<style scoped>
</style>
