import ContractProposalListPage from "../views/list/Contract/ContractProposalListPage.vue";
import ProposalListPage from "../views/list/Proposal/ProposalListPage.vue";


export const Contract = [
  //Contrato
  {
    path: '/commercial/contract',
    name: 'commercial.contract.index',
    component: ContractProposalListPage,
    meta: {
      title: 'ContractProposal-Contract',
      description: 'Contrato',
      permission: 'commercial.contract-proposal.index.view',
    },
  },
];

export const Proposal = [
  //Proposta
  {
    path: '/commercial/proposal',
    name: 'commercial.proposal.index',
    component: ProposalListPage,
    meta: {
      title: 'ContractProposal-Proposal',
      description: 'Proposta',
      permission: 'commercial.contract-proposal.index.view',
    }
  },
]