<template>
  <div>
    <modal class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="modal.create">
        <div>
          <div class="row mb-2 p-0 pr-2 pl-2">
            <div class="col-md-4 mb-1">
              <base-button size="sm" :class="type === 1 && 'active'" @click.prevent="type = 1" block outline type="primary"
                >Dados
              </base-button>
            </div>
            <div class="col-md-4">
              <base-button size="sm" :class="type === 3 && 'active'" @click.prevent="type = 3" block outline type="primary"
                >Documentos
              </base-button>
            </div>
            <div class="col-md-4 mb-1">
              <base-button
                size="sm"
                :class="type === 2 && 'active'"
                :disabled="bill_pay.installments.length < 1 || bill_pay.total_value === '0,00'"
                @click.prevent="type = 2"
                block
                outline
                type="primary"
                :loading="loadingInstallment"
                >Parcelas
              </base-button>
            </div>
          </div>
          <div class="border-left border-3 border-danger pr-1 mt-3 mb-2">
            <div class="col-md-12">
              <h4 class="m-0">{{ bill_pay.entity.entity_name }}</h4>
              <h4 class="m-0 font-weight-normal">{{ bill_pay.entity.document }}</h4>
            </div>
          </div>
          <div v-if="hasPaidInstallment === false" class="form-group row m-0 p-0 mb-3 mt-3">
            <div class="col-md-6">
              <base-button
                type="indigo"
                outline
                @click.prevent="changeAccumulation"
                :class="{ active: bill_pay.accumulation === 1 }"
              >
                <i style="font-size: 17px !important" class="fas fa-history text-center" /> Acumular
              </base-button>
            </div>
          </div>
          <hr v-show="type !== 1 && hasPaidInstallment === false" class="mb-4 mt-3 pb-1 ml-3 mr-3" />
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
              <div>
                <section v-show="type == 1">
                  <div class="border-header-success-bottom mt-3 mb-2">
                    <h5 class="h4 p-1 mb-0">Identificação</h5>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pb-1 pt-2 col-form-label form-control-label">
                      Data
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker @input="changeInstallments(false)" :mode="'date'" :disabled="bill_pay.started || bill_pay.accumulation === 1" v-model="bill_pay.date" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-0">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                      Ocorrência <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <div class="col-12 p-0">
                        <base-input input-group-classes="input-group-sm">
                          <input disabled type="text" v-model="bill_pay.occurrence" class="form-control form-control-sm" />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0"> dias </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                      Valor total (a pagar)
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider :rules="valueRequired(bill_pay.total_value)">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            @blur="changeInstallments(false)"
                            v-money="money"
                            maxlength="13"
                            inputmode="numeric"
                            v-model.lazy="bill_pay.total_value"
                            type="text"
                            :disabled="bill_pay.started || bill_pay.accumulation === 1 || bill_pay.entries_launches.length > 0"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0"> R$ </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                      Valor final
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          :value="finalValue"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <hr class="m-0 mb-2 mt-3 pb-1 ml-3 mr-3" />
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Central
                       <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="changedCompanyPlant(bill_pay)"
                          v-model="bill_pay.company_plant_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingCompanyPlant || bill_pay.started || bill_pay.accumulation === 1 || bill_pay.entries_launches.length > 0"
                        >
                          <el-option
                            v-for="company_plant in $_company_plants"
                            :key="company_plant.id"
                            :label="company_plant.name"
                            :value="company_plant.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> CNPJ Comprador
                       <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                       <el-select
                          v-model="bill_pay.company_plant_buyer_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          @change="getDocuments(bill_pay)"
                          :disabled="loadingCompanyPlantBuyer || bill_pay.started || bill_pay.accumulation === 1 || bill_pay.entries_launches.length > 0"
                        >
                          <el-option
                            v-for="company_plant_buyer in company_plant_buyers"
                            :key="company_plant_buyer.id"
                            :label="company_plant_buyer.issuer.ein"
                            :value="company_plant_buyer.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Tipo
                       <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                            :disabled="loadingProductServiceHubType || bill_pay.started || bill_pay.accumulation === 1 || bill_pay.entries_launches.length > 0"
                            filterable
                            v-model="bill_pay.product_service_hub_type_id"
                            size="mini"
                            placeholder="Selecione"
                          >
                            <el-option label="MCC" :value="2"> </el-option>
                            <el-option v-for="type in $_product_service_hub_types" :key="type.id" :label="type.name" :value="type.id">
                            </el-option>
                          </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Conta bancária  <span class="text-danger">&nbsp;*</span> </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model.lazy="bill_pay.bank_account_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingBankAccount || bill_pay.started"
                        >
                          <el-option
                            v-for="bank_account in bank_accounts"
                            :key="bank_account.bank_account_id"
                            :label="bank_account.bank_account.name"
                            :value="bank_account.bank_account_id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Método de pagamento </label>
                    <div class="col-md-6">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model.lazy="bill_pay.payment_method_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingPaymentMethod"
                        >
                          <el-option v-for="method in $_payment_methods" :key="method.id" :label="method.name" :value="method.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Descrição </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input type="text" v-model="bill_pay.description" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                </section>

                <section class="mt-3" v-show="type == 2">
                  <div v-if="bill_pay.installments.length">
                    <div class="form-group row m-0 mt-2 mb-4 p-0">
                      <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                        Quantidade de Parcelas <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            :disabled="bill_pay.started"
                            inputmode="numeric"
                            v-on:input="quantity_installments = formatString($event.target.value, '0', '99')"
                            v-model="quantity_installments"
                            @change="changeInstallments"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </div>
                    </div>
                    <div v-for="(installment, indexInstallment) in paginatedInstallment" :key="indexInstallment">
                      <div class="form-group row m-0 mb-1 limited-row" :class="installment.index > 1 ? 'mt-4' : ''">
                        <label class="col-md-6 pt-2 pb-0 pt-1 col-form-label form-control-label font-weight-bold">
                          Parcela {{ installment.index }}
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-button
                              block
                              size="sm"
                              disabled
                              :type="bill_pay_types[installment.status].color"
                              class="text-uppercase"
                          >
                            {{ bill_pay_types[installment.status].text }}
                          </base-button>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row mt-2">
                        <label class="col-md-6 pb-1 pt-2 col-form-label form-control-label">
                          Data de vencimento
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <validation-provider rules="required">
                            <base-input input-group-classes="input-group-sm">
                              <input-date-time-picker :disabled="installment.status === 1 || !$hasPermission('bill_pay.change_due_date')" @input="checkInstallmentStatus(installment)" :mode="'date'" v-model="installment.due_date" />
                            </base-input>
                          </validation-provider>
                        </div>
                      </div>
                      <div v-show="installment.status === 1" class="form-group row m-0 p-0 mb-1 limited-row">
                        <label class="col-md-6 pb-1 pt-2 col-form-label form-control-label"> Data do pagamento </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker disabled :mode="'date'" v-model="installment.paid_at" />
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                          Valor total
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <validation-provider :rules="valueRequired(installment.total_value)">
                            <base-input input-group-classes="input-group-sm">
                              <input
                                :disabled="indexInstallment === 0 || bill_pay.entries_launches.length > 0 || installment.status === 1"
                                v-money="money"
                                maxlength="13"
                                inputmode="numeric"
                                v-model.lazy="installment.total_value"
                                @blur="changedInstallmentTotalValue($event.target.value, indexInstallment, bill_pay.installments, bill_pay)"
                                type="text"
                                class="form-control form-control-sm"
                              />
                              <template slot="prepend">
                                <small class="input-group-sm p-0 m-0"> R$ </small>
                              </template>
                            </base-input>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Descontos/Taxas </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              :disabled="installment.status === 1 || !$hasPermission('bill_pay.edit_discounts_and_taxes')"
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              v-money="money"
                              maxlength="10"
                              inputmode="numeric"
                              @blur="calcFinalValue(installment)"
                              v-model.lazy="installment.discounts_fees"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Juros/Multas </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              @blur="calcFinalValue(installment)"
                              v-money="money"
                              maxlength="10"
                              inputmode="numeric"
                              v-model.lazy="installment.fees_fines"
                              class="form-control form-control-sm"
                              :disabled="installment.status === 1"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                          Valor final
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              disabled
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model.lazy="installment.final_value"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Conta bancária de crédito </label>
                        <div class="col-md-6 v-select-financial-no-hover-issue">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              :clearable="false"
                              v-model="installment.bank_account_id"
                              :items="bank_accounts"
                              :label="['bank_account', 'name']"
                              :recursiveKey="['bank_account', 'id']"
                              :labelMask="true"
                            />
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-0">
                        <label class="col-md-4 pt-2 pb-0 col-form-label form-control-label">
                          Anexo
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-8">
                          <base-input input-group-classes="input-group-sm">
                            <InputFile :multiple="false" ref="installmentAttachment" @input="installment.file = $event" />
                          </base-input>
                        </div>
                      </div>
                      <div v-show="bill_pay.installments && bill_pay.installments.length > 1" class="p-3">
                        <pagination @navegate="handleInstallmentNavegate" :source="source" />
                      </div>
                    </div>
                  </div>
                </section>
                <section v-show="type == 3">
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Total (documentos) </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="bill_pay.document_value"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Saldo </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          :value="balance"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row custom-input-select">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Lançamentos de despesas</label>
                    <div class="col-md-6">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          :disabled="loadingEntryLaunch || bill_pay.started"
                          v-model="bill_pay.entries_launches"
                          size="mini"
                          @change="calcDocumentValue"
                          placeholder="Selecione"
                          filterable
                          multiple
                          collapse-tags
                        >
                          <el-option
                            v-for="entry_launch in $_entries_launches"
                            :key="entry_launch.id"
                            :label="formatEntryLaunchLabel(entry_launch)"
                            :value="entry_launch.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </section>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  :disabled="invalid || !bill_pay.installments.length || bill_pay.installments[0].status === 3"
                  :loading="loadingStore"
                >
                  <span v-show="!loadingStore" class="btn-label"><i class="fas fa-save"></i></span>
                  Salvar
                </base-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlFile from "@/components/PuzlFile";
import { VMoney } from "v-money";
import { checkNumberValue, moneyToFloat, toMoney } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import billPayMixin from "../Mixins/bill_pay_mixin";
import entryLaunchMixin from "../../../Configuration/EntryLaunch/Mixins/entry_launch_mixin";
import billPayInstallmentMixin from "../Mixins/bill_pay_installment_mixin";
import { hasPermission } from "@/plugins/permissions";
import Pagination from "@/components/Utils/Pagination";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEditBillPay",
  mixins: [billPayMixin, entryLaunchMixin, billPayInstallmentMixin],
  components: {
    InputFile,
    PuzlFile,
    InputDateTimePicker,
    Pagination,
    PuzlSelect,
  },
  data() {
    return {
      loadingPaymentMethod: false,
      loadingEntryLaunch: false,
      loadingCompanyPlantBuyer:false,
      loadingProductServiceHubType:false,
      loadingCompanyPlant:false,
      loadingBankAccount:false,
      loadingInstallment : false,
      modal: {
        title: "Contas a pagar",
        create: false,
      },
      type: 1,
      old_installments: [],
      quantity_installments: null,
      loadingStore: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      has_permission: hasPermission,
      company_plant_buyers:[],
      bank_accounts:[],
    };
  },
  directives: {
    money: VMoney,
  },
  computed: {
    ...mapGetters({
      $_payment_methods: "paymentMethod/fetch",
      $_entries_launches: "entryLaunch/fetch",
      bill_pay: "billPay/show",
      $_company_plants: "plant/activeItems",
      $_product_service_hub_types: "productServiceHubType/fetch",
    }),
    finalValue() {
      return this.bill_pay.installments
        .reduce(function (a, item) {
          return a + (item.final_value.includes(',') ? moneyToFloat(item.final_value) : parseFloat(item.final_value));
        }, 0)
        .toFixed(2);
    },
    balance() {
      return this.bill_pay.total_value && this.bill_pay.document_value
        ? (moneyToFloat(this.bill_pay.total_value) - moneyToFloat(this.bill_pay.document_value)).toFixed(2)
        : 0;
    },
    hasPaidInstallment() {
      return this.bill_pay.installments.findIndex(item => item.status === 1) !== -1
    },
    paginatedInstallment() {
      if(!this.bill_pay.installments.length || this.loadingInstallment){
        return []
      }

      return [this.bill_pay.installments[this.source.meta.current_page-1]];
    },
  },
  methods: {
    closeModal() {
      this.resetInstallmentPage([""]);
      this.modal.create = false;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    formatEntryLaunchLabel(item) {
      return `${item.issue_date} | Nº ${item.document_number} | ${toMoney(item.final_value)}`;
    },
    async openModal(id, index) {
      this.loadingPaymentMethod = true;
      this.loadingEntryLaunch = true;
      this.loadingProductServiceHubType = true;
      this.loadingCompanyPlantBuyer = true;
      this.loadingCompanyPlant = true;
      this.loadingInstallment = true;
      this.type = 1;
      await this.$store.dispatch("billPay/show", id).then((response) => {
        this.bill_pay.date = this.bill_pay.date + " 00:00:00";
        this.bill_pay.installments.sort((a, b) => a.index < b.index ? -1 : true);
        this.getCompanyPlantBuyers();
        this.getBankAccounts(this.bill_pay, true);
        this.modal.create = true;
      });
      this.quantity_installments = this.bill_pay.installments.length;
      this.old_installments = JSON.parse(JSON.stringify(this.bill_pay.installments));
      this.getDocuments(this.bill_pay);
      await this.$store.dispatch("paymentMethod/fetchItems").then((response) => {
        this.loadingPaymentMethod = false;
      });
      await this.$store.dispatch("productServiceHubType/fetch").then(() => {
        this.loadingProductServiceHubType = false;
      });
      await this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.loadingCompanyPlant = false;
      });

      this.resetInstallmentPage(this.bill_pay.installments);

      await this.bill_pay.installments.map(function (item, mapIndex) {
        if (item.file) {
          delete item.file.id;
          delete item.file.bill_pay_installment_id;
        }
        item.due_date = item.due_date + " 00:00:00";
        item.paid_at =  item.paid_at ? item.paid_at + " 00:00:00" : null;
        item.original_due_date = item.original_due_date ? item.original_due_date + " 00:00:00" : null;

        if(mapIndex === this.bill_pay.installments.length -1) {
          this.handleInstallmentNavegate(mapIndex+1);
          this.loadingInstallment = false;
        }
      }, this);
    },
    changeAccumulation() {
      if (!this.bill_pay.accumulation) {
        this.bill_pay.accumulation_time = null;
        this.bill_pay.accumulation = 1;
      } else {
        this.bill_pay.accumulation = 0;
      }
      this.$forceUpdate();
    },
    finalFormat() {
      if(this.hasPaidInstallment) {
        this.bill_pay.accumulation = 0;
      }
      let payload = {
        bill_pay: JSON.parse(JSON.stringify(this.bill_pay)),
        installments: JSON.parse(JSON.stringify(this.bill_pay.installments)),
        old_installments: this.old_installments,
        entries_launches: JSON.parse(JSON.stringify(this.bill_pay.entries_launches)),
      };
      let formData = new FormData();
      payload.bill_pay.total_value = moneyToFloat(payload.bill_pay.total_value);
      payload.bill_pay.document_value = moneyToFloat(payload.bill_pay.document_value);
      payload.bill_pay.final_value = this.finalValue;
      payload.bill_pay.balance = this.balance;
      payload.bill_pay.date = moment(payload.bill_pay.date).format("YYYY-MM-DD");
      delete payload.bill_pay.installments;
      delete payload.bill_pay.entity;
      payload.installments.map(function (obj) {
        obj.bank_account_id = obj.bank_account_id ? obj.bank_account_id : payload.bill_pay.bank_account_id;
        obj.fees_fines = obj.fees_fines !== '0,00' ? this.normalizeValue(obj.fees_fines) : null;
        obj.discounts_fees = obj.discounts_fees !== '0,00' ? this.normalizeValue(obj.discounts_fees) : null;
        obj.due_date = moment(obj.due_date).format("YYYY-MM-DD");
        obj.original_due_date = obj.original_due_date  ? moment(obj.original_due_date).format("YYYY-MM-DD") : null;
        obj.paid_at = obj.paid_at  ? moment(obj.paid_at).format("YYYY-MM-DD") : null;
        obj.total_value = this.normalizeValue(obj.total_value);
        obj.final_value = this.normalizeValue(obj.final_value);
        if(obj.file) {
           obj.file = obj.file && !obj.file.file ? 'keep_file' : obj.file;
        } else {
          delete obj.file;
        }
      }, this);
      for (let key of Object.keys(payload.bill_pay)) {
        formData.append(`bill_pay[${key}]`, payload.bill_pay[key] !== null ? payload.bill_pay[key] : "");
      }
      payload.installments.map(function (obj, index) {
        for (let key of Object.keys(payload.installments[index])) {
          formData.append(
            `installments[${index}][${key}]`,
            payload.installments[index][key] !== null ? payload.installments[index][key] : ""
          );
        }
        /* Verifica se há anexo para a parcela */
        if (obj.file && this.bill_pay.installments[index].file.file && this.bill_pay.installments[index].file.file instanceof File) {
          formData.append(`${index}[file]`, this.bill_pay.installments[index].file.file);
        }
      }, this);
      if (payload.entries_launches.length) {
        payload.entries_launches.map(function (obj, index) {
          formData.append(`entries_launches[${index}]`, obj);
        });
      }
      formData.append("_method", "PUT");
      return formData;
    },
    update() {
      this.loadingStore = true;
      let payload = this.finalFormat();
      this.$store
        .dispatch(
          "billPay/update",
          { data: payload, old_installments: this.old_installments },
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
    async changeInstallments(show_message = true) {
      this.bill_pay.installments = [];
      let val = parseInt(this.quantity_installments);
      switch (true) {
        /* caso não haja parcelas a serem manipuladas */
        case !val:
          this.quantity_installments = 1;
          if (show_message) {
            this.$notify({ type: "danger", message: "Deve haver ao menos uma parcela!" });
          }
          await this.generateInstallments(1);
          break;
        default:
          await this.generateInstallments(val);
      }
      this.resetInstallmentPage(this.bill_pay.installments);
    },
    /** Recalcula o valor total dos documentos e dispara parcelas */
    calcDocumentValue() {
      this.bill_pay.document_value = 0;
      this.bill_pay.entries_launches.map(function (id) {
        this.bill_pay.document_value += parseFloat(this.$_entries_launches.filter((item) => item.id === id)[0].final_value);
      }, this);
      this.bill_pay.document_value = this.bill_pay.document_value.toFixed(2);
      this.bill_pay.total_value = toMoney(this.bill_pay.document_value).replace("R$", "");
      this.changeInstallments(false);
    },
    generateInstallments(to_generate) {
      /* valor total informado pelo usuário */
      let bill_pay_total_value = moneyToFloat(this.bill_pay.total_value);
      /* Valor por parcela */
      let value_per_installment = (bill_pay_total_value / to_generate).toFixed(2);

      for (let i = 0; i < to_generate; i++) {
        let date = moment(this.bill_pay.date).add(i, "months").format("YYYY-MM-DD") + " 00:00:00";
        this.bill_pay.installments.push({
          status: 0,
          index: i + 1,
          due_date: date,
          original_due_date: date,
          paid_at: null,
          discounts_fees: null,
          fees_fines: null,
          final_value: value_per_installment,
          total_value: value_per_installment,
        });
      }
      let real_total_value = this.bill_pay.installments.reduce(function (a, item) {
        return a + parseFloat(item.total_value);
      }, 0);

      /** Diferença de valor real e teórico */
      let difference = (real_total_value - bill_pay_total_value).toFixed(2);

      if (difference !== 0) {
        /** Checa integridade do valor */
        switch (true) {
          case difference > 0:
            this.bill_pay.installments[0].total_value = (
              parseFloat(this.bill_pay.installments[0].total_value) - difference
            ).toFixed(2);
            this.bill_pay.installments[0].final_value = (
              parseFloat(this.bill_pay.installments[0].final_value) - difference
            ).toFixed(2);
            break;
          case difference < 0:
            difference = Math.abs(difference);
            this.bill_pay.installments[0].total_value = (
              parseFloat(this.bill_pay.installments[0].total_value) + difference
            ).toFixed(2);
            this.bill_pay.installments[0].final_value = (
              parseFloat(this.bill_pay.installments[0].final_value) + difference
            ).toFixed(2);
            break;
        }
      }
      return difference;
    },
    valueRequired(value) {
      return value === "0,00" ? "min_value:1" : "";
    },
    removeInstallments(to_remove) {
      this.bill_pay.installments.splice(to_remove);
    },
    getCompanyPlantBuyers() {
      this.loadingCompanyPlantBuyer = true;
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.bill_pay.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        if (this.company_plant_buyers.length) {
          let default_company_plant_buyer =
            this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          this.bill_pay.company_plant_buyer_id = default_company_plant_buyer.id;
          this.getDocuments(this.bill_pay)
        } else {
          this.bill_pay.company_plant_buyer_id = null;
        }
        this.loadingCompanyPlantBuyer = false;
      });
    },
    checkInstallmentStatus(installment) {
      if(installment.status === 2 && moment(installment.due_date).isSameOrAfter(moment().format('YYYY-MM-DD')))
      {
        installment.status = 0;
      }
    },
    /**
     * Realiza verificação de anexo e dispara navegação entre as páginas
     *
     * @param {integer} page
     */
    handleInstallmentNavegate(page) {
      if(this.$refs.installmentAttachment && this.$refs.installmentAttachment[0]) {
        this.$refs.installmentAttachment[0].fileRecords = [];
        this.$refs.installmentAttachment[0].fileRecordsForUpload = [];
      }
      if(this.bill_pay.installments[page -1] && this.bill_pay.installments[page -1].file) {
        const file = this.bill_pay.installments[page -1].file;
        this.$refs.installmentAttachment[0].setFileByObject(file, true);
      }
      this.$forceUpdate();
      this.navegate(page, this.bill_pay.installments);
    },
  },
  mounted() {},
};
</script>

<style>
.bill-modal-create {
  overflow-y: scroll !important;
}

@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}
.custom-input-select .el-select__tags-text {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
</style>
