<template>
  <div
    class="card-body mt-n2 p-0">
    <div
      class="row mb-5">
      <ShadedTable
        :length="bills_items.length"
        :headers="headers"
        :customHeaders="[0]"
        :loading="loadingSkeleton">
        <template slot="header_0">
          <input type="checkbox" @click="$emit('selectAll')">
        </template>
        <template
          v-for="(item, index) in bills_items"
          :slot="index">
          <ShadedCol
            align="center" style="width: 15px;">
            <input type="checkbox"
              class="pointer"
              v-if="item.status.status !== BillReceiveInstallmentEnum.RECEIVED && item.status.status !== BillReceiveInstallmentEnum.CANCELED"
              :checked="paid_allotments.includes(item.id)"
              @change="paid_allotments.includes(item.id) ? $emit('addSetPaidAllotments', item.id) :  $emit('addSetPaidAllotments', item.id, index)"
            />
          </ShadedCol>
          <ShadedCol align="center" customStyle="display: flex;">
            <BillPayPossibleActions
              @changeInstallmentStatus="changeInstallmentStatus"
              :listType="listType"
              :bill_pay="item"
            />
          </ShadedCol>
          <ShadedCol>
            <div>
              <el-popover
                trigger="click"
                placement="top"
              >
                <span
                  style="width: 230px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                  class="row">
                  <div
                    class="col-12 mb-2"
                    style="display: flex;
                    align-items: center;">
                    <h4
                      style="font-size: 14px;"
                      class="p-0 m-0 font-weight-500 new-default-black-font">
                      CPF/CNPJ
                    </h4>
                  </div>
                  <div
                    class="col-12 mb-2 px-0">
                    <hr class="gray-divider m-0">
                  </div>
                  <div
                    class="col-12">
                    <h3
                      class="m-0 mb-1 font-weight-400 new-default-black-font">
                      {{ item.entity_document }}
                    </h3>
                  </div>
                </span>
                <h5
                  slot="reference"
                  class="m-0 font-weight-400 new-default-black-font pointer"
                  style="width: fit-content;"
                >
                  {{ truncateText(item.entity_name, 43) }}
                </h5>
              </el-popover>
            </div>
            <div>
              <el-popover
                trigger="click"
                placement="bottom"
              >
                  <span
                    style="width: 190px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row">
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;">
                      <h4
                        style="font-size: 14px;"
                        class="p-0 m-0 font-weight-500 new-default-black-font">
                        Conta Bancária
                      </h4>
                    </div>
                    <div
                      class="col-12 mb-2 px-0">
                      <hr class="gray-divider m-0">
                    </div>
                    <div
                      class="col-12">
                      <h5
                        class="font-weight-normal new-default-black-font m-0 mb-1">
                        {{ item.bank_account_name }}
                      </h5>
                    </div>
                  </span>
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 mr-2 shadow-none text-indigo"
                >
                  <img
                    src="/img/icons/merchant-account.png"
                    width="25"
                  >
                </base-button>
              </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div class="container px-0" style="width: 100%;">
              <div class="row align-items-center">
                <div class="col-6">
                  <h5
                    class="m-0 mt-1 new-default-black-font font-weight-normal">
                    {{ item.type_name }}
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <el-popover
                    trigger="click"
                    placement="bottom"
                    v-if="item.document_observations"
                  >
                      <span
                        style="width: 270px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                        class="row">
                        <div
                          class="col-12 mb-2"
                          style="display: flex;
                          align-items: center;">
                          <h4
                            style="font-size: 14px;"
                            class="p-0 m-0 font-weight-500 new-default-black-font">
                            Observações
                          </h4>
                        </div>
                        <div
                          class="col-12 mb-2 px-0">
                          <hr class="gray-divider m-0">
                        </div>
                        <div
                          class="col-12">
                          <h5
                            class="font-weight-normal new-default-gray-font m-0 mb-1">
                            {{ item.document_observations }}
                          </h5>
                        </div>
                      </span>
                    <base-button
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 shadow-none text-indigo"
                    >
                      <img
                        src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png"
                        width="25"
                      >
                    </base-button>
                  </el-popover>
                </div>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div v-if="item.document_quantity > 0"
              class="pointer"
              @click.prevent="$emit('handleModalListEntriesLaunches', item.bill_pay_id)"
              style="display: flex; align-items: center;">
              <img
                src="/img/icons/icons8/ios/bill_primary.png"
                class="mr-1"
                width="25">
              <h5
                class="m-0 mt-1 new-default-black-font font-weight-normal">
                {{ item.document_quantity > 1 ?  `Agrupadas (${item.document_quantity })` : item.document_numbers}}
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div class="container px-0" style="width: 100%;">
              <div class="row align-items-center">
                <div class="col-6">
                  <h5 v-if="item.payment_method_name"
                    class="m-0 mt-1 new-default-black-font font-weight-normal">
                    {{ item.payment_method_name }}
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <base-button
                    v-if="item.attachment_url"
                    @click.prevent="$emit('handleModalBillPayInstallmentAttachment', item)"
                    outline
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img
                      src="/img/icons/attach-green.png"
                      width="25"
                    >
                  </base-button>
                  <base-button
                    v-show="item.payment_method_id && [1, 4].includes(item.payment_method_id)"
                    @click.prevent="$emit('handleEntityAccount', item.entity_id)"
                    outline
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 mr-2 shadow-none text-indigo"
                  >
                    <img
                      src="/img/icons/bank-building-red.png"
                      width="25"
                    >
                  </base-button>
                </div>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5
                class="m-0 mt-1 new-default-black-font font-weight-normal">
                  {{ $helper.parseDate(item.due_date, 'DD MMM YYYY') }}
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5
                class="m-0 mt-1 new-default-black-font font-weight-normal">
                {{ item.paid_at }}
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div class="container px-0" style="width: 100%;">
              <div class="row align-items-center">
                <div class="col-6">
                  <el-popover
                  trigger="hover"
                  placement="right">
                  <span
                    style="width: 350px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row">
                      <div
                        class="col-12 mb-2"
                        style="display: flex;
                        align-items: center;">
                        <AppIcon
                          icon="chemical-plant"
                          width="25"
                        />
                        <h4
                          style="font-size: 14px;"
                          class="p-0 ml-2 m-0 font-weight-500 new-default-black-font">
                          Central:
                        </h4>
                      </div>
                      <div class="col-12 mb-2">
                        <h4 class="m-0 new-default-black-font font-weight-normal">
                          {{ item.company_plant_name }}
                        </h4>
                        <h4 class="m-0 new-default-black-font font-weight-normal">
                          {{ item.buyer_ein	}}
                        </h4>
                      </div>
                      <div
                        class="col-12 mb-2 px-0">
                        <hr class="gray-divider m-0">
                      </div>
                      <div
                        class="col-12 mb-2"
                        style="display: flex;
                        align-items: center;">
                        <img
                          src="/img/icons/icons8/ios/transaction-approved_danger.png"
                          width="25"
                        />
                        <h4
                          style="font-size: 14px;"
                          class="p-0 ml-2 m-0 font-weight-500 new-default-black-font">
                          Dados da parcela:
                        </h4>
                      </div>
                      <div class="container">
                        <div class="row align-items-center mb-2">
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              Data do vencimento
                            </h4>
                          </div>
                          <div class="col px-0">
                            <hr class="gray-divider m-0">
                          </div>
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              {{ $helper.parseDate(item.due_date, 'DD MMM YYYY') }}
                            </h4>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              Vencimento Original
                            </h4>
                          </div>
                          <div class="col px-0">
                            <hr class="gray-divider m-0">
                          </div>
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              {{ $helper.parseDate(item.original_due_date, 'DD MMM YYYY') }}
                            </h4>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2" v-if="item.paid_at">
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              Data de pagamento
                            </h4>
                          </div>
                          <div class="col px-0">
                            <hr class="gray-divider m-0">
                          </div>
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              {{ item.paid_at }}
                            </h4>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              Valor
                            </h4>
                          </div>
                          <div class="col px-0">
                            <hr class="gray-divider m-0">
                          </div>
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              {{ $helper.toMoney(item.total_value) }}
                            </h4>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              Desconto
                            </h4>
                          </div>
                          <div class="col px-0">
                            <hr class="gray-divider m-0">
                          </div>
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              {{ $helper.toMoney(item.discounts_fees) }}
                            </h4>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              Juros/Multa
                            </h4>
                          </div>
                          <div class="col px-0">
                            <hr class="gray-divider m-0">
                          </div>
                          <div class="col-auto">
                            <h4 class="new-default-black-font m-0 font-weight-normal">
                              {{ $helper.toMoney(item.fees_fines) }}
                            </h4>
                          </div>
                        </div>
                        <div class="row align-items-center mx-0 mb-2 py-1" style="background: #FCF3F3; border-radius: 4px;">
                          <div class="col-auto pl-1">
                            <h4 class="new-default-black-font m-0">
                              Valor Final
                            </h4>
                          </div>
                          <div class="col px-0">
                            <hr class="gray-divider m-0">
                          </div>
                          <div class="col-auto pr-1">
                            <h4 class="new-default-black-font m-0">
                              {{ $helper.toMoney(item.final_value) }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </span>
                  <h5
                    slot="reference"
                    class="m-0 new-default-black-font font-weight-500">
                    {{ $helper.toMoney(item.total_value) }}
                  </h5>
                </el-popover>
                </div>
                <div class="col-6 text-right">
                  <div
                  class="no-shadow-card-blue"
                  style="display: inline-flex;">
                  <h4
                    class="m-0 p-0">
                    {{ item.index + "/" + item.last_index }}
                  </h4>
                </div>
                </div>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <SettingOptions
              :bill_pay="item"
              :listType="listType"
              @handleEditBillPay="handleEditBillPay"
              @handleModalShareInstallment="handleModalShareInstallment"
              @handleAgroup="handleAgroup"
              @removeAgroup="removeAgroup"
              @getPaymentAuthorizationProof="getPaymentAuthorizationProof"
              @handleHistory="handleHistory"
              @handleDeleteBillPayInstallment="handleDeleteBillPayInstallment"
            />
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>
<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import LoadingPagination from "@/components/LoadingPagination";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import SettingOptions from "./Common/SettingOptions.vue";
import BillPayPossibleActions from "@/views/Modules/Financial/BillPay/Shared/Common/BillPayPossibleActions.vue";
import { BillReceiveInstallmentEnum } from "@/enum/BillReceiveInstallmentEnum";
import {AppIcon} from "@/components/AppGlobal";

export default {
  name: "TableBillPay",
  props: {
    loading: Boolean,
    bills_items: Array,
    listType: String,
    paid_allotment: Boolean,
    paid_allotments: Array,
    loadingSkeleton: Boolean,
    loadingInstallment: Boolean,
  },
  components: {
    AppIcon,
    BillPayPossibleActions,
    SettingOptions,
    ShadedCol,
    ShadedTable,
    LoadingPagination,
    SkeletonPuzl
  },
  data(){
    return {
      headers: ["", "Status", "Fornecedor", "Tipo", "Documento", "Método", "Vencimento", "Débito", "Valor", "Ação"],
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
    }
  },
  methods: {
    truncateText(text, limit = 10){
      return text.length > limit ? text.slice(0, limit) + "..." : text;
    },
    changeInstallmentStatus(bill_pay_id, status_value){
      this.$emit('changeInstallmentStatus', bill_pay_id, status_value)
    },
    handleEditBillPay(bill_pay){
      this.$emit('handleEditBillPay', bill_pay)
    },
    handleModalShareInstallment(bill_pay){
      this.$emit('handleModalShareInstallment', bill_pay)
    },
    handleAgroup(bill_pay){
      this.$emit('handleAgroup', bill_pay)
    },
    removeAgroup(bill_pay){
      this.$emit('removeAgroup', bill_pay)
    },
    getPaymentAuthorizationProof(bill_pay){
      this.$emit('getPaymentAuthorizationProof', bill_pay)
    },
    handleHistory(bill_pay){
      this.$emit('handleHistory', bill_pay)
    },
    handleDeleteBillPayInstallment(bill_pay){
      this.$emit('handleDeleteBillPayInstallment', bill_pay)
    }
  }
};
</script>
<style scoped>
.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #7498C7;
  padding: 0px 5px;
  /* box-shadow: 0 0 2px 0 #0b7bfd52; */
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}
</style>
