import stockLocationRoute from '../../modules/configuration/stock-location/routes/stockLocationRoute';
import bankAccountRoute from '@/modules/configuration/bank-account/routes/bankAccountRoute.js'
import costCenterRoute from '../../modules/configuration/cost-center/routes/costCenterRoute';
import IntegrationRoute from '../../../src/views/Modules/Configuration/Integration/routes/integrationRoute';

import ChargePoint from '@/views/Modules/Configuration/Plant/ChargePoint/Index.vue';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import Entity from '@/views/Modules/Configuration/Entity/Index';
import EntityCategory from '@/views/Modules/Configuration/Entity/EntityCategory/Index';

import General from '@/views/Modules/Configuration/General.vue';
import Plant from '@/views/Modules/Configuration/Plant/Plant.vue';
import IssuerDocument from '@/views/Modules/Configuration/Plant/IssuerDocument/Index';
import CreateIssuerDocument from '@/views/Modules/Configuration/Plant/IssuerDocument/Shared/_ModalCreate';
import EditIssuerDocument from '@/views/Modules/Configuration/Plant/IssuerDocument/Shared/_ModalEdit';
import IssuerDocumentNfse from '@/views/Modules/Configuration/Plant/IssuerDocument/Shared/_ModalNfse';
import Additional from '@/views/Modules/Configuration/Additional/Grid.vue';
import Service from '@/views/Modules/Configuration/Service/Grid.vue';
import Temperature from '@/views/Modules/Configuration/Temperature/Index.vue';
import FeesAndTaxes from '@/views/Modules/Configuration/FeesAndTaxes/Index.vue';
import FeesAndTaxesTributes from '@/views/Modules/Configuration/FeesAndTaxes/Tributes/Index.vue';
import FeesAndTaxesDeductionRules from '@/views/Modules/Configuration/FeesAndTaxes/DeductionRules/Index.vue';
import Water from '@/views/Modules/Configuration/Water/Index.vue';
import CostCenter from '@/views/Modules/Configuration/CostCenter/Index.vue';
import Commission from '@/views/Modules/Configuration/Commission/Index.vue';
import Moisture from '@/views/Modules/Configuration/Moisture/Index.vue';
import ChartAccount from '@/views/Modules/Configuration/ChartAccount/Index.vue';
import ChartAccountResume from '@/views/Modules/Configuration/ChartAccount/Resume/Index.vue';

// Start User
import User from '@/views/Modules/Configuration/User/Index.vue';
import UserCreate from '@/views/Modules/Configuration/User/Shared/_Create.vue';
import UserEdit from '@/views/Modules/Configuration/User/Shared/_Edit.vue';
import JobDescription from '@/views/Modules/Configuration/User/JobDescription/Index';
import ContractRegime from '@/views/Modules/Configuration/User/ContractRegime/Index';
import AttachmentType from '@/views/Modules/Configuration/User/AttachmentType/Index';
import UserAlerts from '@/views/Modules/Configuration/User/UserAlert/Index';
// End User

// Start Auth Permissions
import Menu from '@/views/Modules/Configuration/Menu/Index.vue';
import GroupPermission from '@/views/Modules/Configuration/User/GroupPermission/Index';
import Permission from '@/views/Modules/Configuration/User/Permission/Index';
// End Auth Permission

// Start Product Service
import ProductService from '@/views/Modules/Configuration/ProductService/Index';
import ProductServiceCategory from '@/views/Modules/Configuration/ProductService/Category/Index';
// End Product Service

// Start Entry Launch
import EntryLaunch from '@/views/Modules/Configuration/EntryLaunch/Index';
import EntryLaunchExternal from '@/views/Modules/Configuration/EntryLaunch/External/Index';
import EntryLaunchCreate from '@/views/Modules/Configuration/EntryLaunch/Shared/_Create';
import EntryLaunchEdit from '@/views/Modules/Configuration/EntryLaunch/Shared/_Edit';
import EntryLaunchExternalCreate from '@/views/Modules/Configuration/EntryLaunch/External/_Create';
import EntryLaunchCreateByReplication from '@/views/Modules/Configuration/EntryLaunch/Shared/_CreateByReplication';
import EntryLaunchCreateByContractProposal from '@/views/Modules/Configuration/EntryLaunch/Shared/_CreateByContractProposal';
import EntryLaunchCreateByEntity from '@/views/Modules/Configuration/EntryLaunch/Shared/_CreateByEntity';
// End Entry Launch

import ExitLaunch from '@/views/Modules/Configuration/ExitLaunch/Index';
import ExitLaunchCreate from '@/views/Modules/Configuration/ExitLaunch/Shared/_Create';
import ExitLaunchEdit from '@/views/Modules/Configuration/ExitLaunch/Shared/_Edit';

// Notification
import Notification from '@/views/Modules/Configuration/Notification/Index';


const configuration =
  {
    path: '/configuration',
    component: DashboardLayout,
    name: 'Cadastro',
    children: [
      {
        path: '/configuration/general',
        name: 'Configurações',
        meta: {
          permission: 'configuration.plant.index.view',
          description: 'Configurações',
        },
        component: General
      },
      {
        path: '/configuration/notification',
        name: 'Notificações',
        meta: {
          title: 'Geral',
          permission: 'configuration.notification.index.view',
          description: 'Notificações',
          icon: 'fa fa-home',
          global_filter: true,
          breadcrumbs: [
            {title: 'Geral', name: null},
            {title: 'Notificações', name: null},
          ]
        },
        component: Notification
      },
      {
        path: '/configuration/plant/:company_id',
        name: 'plant',
        meta: {
          permission: 'configuration.plant.index.view',
        },
        component: Plant,
        props: true
      },
      {
        path: '/configuration/plant/document/issuer-document',
        name: 'configuration.issuer_document',
        meta: {
          permission: 'configuration.issuer-document.index.view',
        },
        component: IssuerDocument,
      },
      {   
        path: '/configuration/plant/document/issuer-document/create',
        name: 'Criar CNPJ',
        component: CreateIssuerDocument
      },
      {
        path: '/configuration/plant/document/issuer-document/edit/:id',
        name: 'Editar CNPJ',
        component: EditIssuerDocument
      },
      {   
        path: '/configuration/plant/document/issuer-document/nfse',
        name: 'Configurar NFS-E',
        component: IssuerDocumentNfse
      },
      {
        path: '/configuration/plant/checkpoint/:company_plant_id',
        name: 'Ponto de Carga',
        component: ChargePoint,
        props: true
      },
      {
        path: '/configuration/entity/dashboard',
        name: 'Clientes e fornecedores',
        component: Entity,
        meta: {
          description: 'Clientes e fornecedores',
          permission: 'configuration.entity.index.view',
      },
        props: true
      },
      {
        path: '/configuration/entity/entity-category',
        name: 'Categoria',
        component: EntityCategory,
        meta: {
          title: 'Cadastro',
          description: 'Categoria',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Cadastro', name: null},
            {title: 'Categoria', name: null},
          ]
        }
      },
      {
        path: '/commercial/additional',
        name: 'Adicionais',
        component: Additional,
        meta: {
          description: 'Adicionais',
          permission: 'commercial.additional.index.view',
        }
      },
      {
        path: '/configuration/fees-and-taxes',
        name: 'fiscal.fees-and-taxes.view',
        component: FeesAndTaxes,
        meta: {
          title: 'Fiscal',
          description: 'Taxas e Tributos',
          icon: 'fa fa-home',
          permission: 'fiscal.fees_and_taxes.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'fiscal.fees_and_taxes.dashboard.index'},
            {title: 'Taxas e Tributos', name: null},
          ]
        },
      },
      {
        path: '/configuration/fees-and-taxes/:uuid/tributes',
        name: 'Tributos',
        meta: {
          permission: 'fiscal.fees_and_taxes.index.view'
        },
        component: FeesAndTaxesTributes
      },
      {
        path: '/configuration/fees-and-taxes/:tribute_uuid/deduction_rules',
        name: 'Regras de dedução',
        meta: {
          permission: 'fiscal.fees_and_taxes.index.view'
        },
        component: FeesAndTaxesDeductionRules
      },
      {
        path: '/configuration/service',
        name: 'Serviços',
        meta: {
          description: 'Serviços',
        },
        component: Service
      },
      {
        path: '/configuration/general/temperature',
        name: 'Temperatura Ambiente',
        meta : {
          description: 'Temperatura Ambiente',
          middleware: false,
        },
        component: Temperature
      },
      {
        path: '/configuration/general/water',
        name: 'Corte de Água',
        meta : {
          description: 'Corte de Água',
          middleware: false,
        },
        component: Water
      },
      
        ...costCenterRoute,
      
      {
        path: '/commercial/commission',
        name: 'Comissões',
        meta: {
          description: 'Comissões',
          permission: 'commercial.comission.index.view',
        },
        component: Commission
      },
      {
        path: '/configuration/general/moisture',
        name: 'Umidade',
        meta : {
          description: 'Umidade',
          middleware: false,
        },
        component: Moisture
      },
      {
        path: '/configuration/general/chart-account',
        meta : {
          description: 'Plano de contas',
          middleware: false,
        },
        name: 'Plano de Contas',
        component: ChartAccount
      },
      {
        path: '/configuration/chart-account-resume',
        name: 'Plano de Contas - Resumo',
        meta: {
          permission: 'administrative.dre.index.view',
        },
        component: ChartAccountResume
      },
      {
        path: '/configuration/user',
        name: 'Usuários',
        component: User,
        meta: {
          permission: 'configuration.user.index.view',
          description: 'Usuários'
        }
      },
      {
        path: '/configuration/user/create',
        name: 'configuration.user.create',
        component: UserCreate,
        meta: {
          title: 'Configurações',
          description: 'Novo Usuário',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.user.dashboard.index'},
            {title: 'Novo Usuário', name: null},
          ]
        }
      },
      {
        path: '/configuration/user/edit/:user_uuid',
        name: 'configuration.user.edit',
        component: UserEdit,
        meta: {
          title: 'Configurações',
          description: 'Editar Usuário',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.edit.dashboard.index'},
            {title: 'Editar Usuário', name: null},
          ]
        }
      },
      {
        path: '/configuration/user/job-description',
        name: 'configuration.job-description.index',
        component: JobDescription,
        meta: {
          title: 'Configurações',
          description: 'Cargos',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.job-description.dashboard.index'},
            {title: 'Cargos', name: null},
          ]
        }
      },
      {
        path: '/configuration/user/contract-regime',
        name: 'configuration.contract-regime.index',
        component: ContractRegime,
        meta: {
          title: 'Configurações',
          description: 'Regime de Contrato',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.contract-regime.dashboard.index'},
            {title: 'Regime de contrato', name: null},
          ]
        }
      },
      {
        path: '/configuration/user/attachment-types',
        name: 'configuration.attachment-types.index',
        component: AttachmentType,
        meta: {
          title: 'Configurações',
          description: 'Tipo de anexo',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.attachment-types.dashboard.index'},
            {title: 'Tipo de anexo', name: null},
          ]
        }
      },
      {
        path: '/configuration/user/user-alert',
        name: 'configuration.user.user-alert.index',
        component: UserAlerts,
        meta: {
          title: 'Alertas de usuário',
          description: 'Alertas de usuário',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.user.alert.dashboard.index'},
            {title: 'Alertas de usuário', name: null},
          ]
        }
      },
      {
        path: '/configuration/user/group-permission',
        name: 'configuration.group-permission.index',
        component: GroupPermission,
        meta: {
          title: 'Configurações',
          description: 'Grupo de Permissão',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.group-permission.dashboard.index'},
            {title: 'Grupo de Permissão', name: null},
          ]
        }
      },
      {
        path: '/configuration/user/permission',
        name: 'configuration.permission.index',
        component: Permission,
        meta: {
          title: 'Configurações',
          description: 'Permissões',
          icon: 'fa fa-home',
          permission: 'configuration.user-permissions.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.permission.dashboard.index'},
            {title: 'Permissões', name: null},
          ]
        }
      },
      {
        path: '/configuration/menu',
        name: 'Busca de Menus',
        component: Menu
      },
      {
        path: '/configuration/product-service',
        name: 'configuration./product-service.view',
        component: ProductService,
        meta: {
          title: 'ProductService',
          description: 'Cadastro de Despesas',
          icon: 'fa fa-home',
          permission: 'entries.product-service.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.product-service.dashboard.index'},
            {title: 'Cadastro de Despesas', name: null},
          ]
        },
      },
      {
        path: '/configuration/product-service/category',
        name: 'configuration.product-service.category',
        component: ProductServiceCategory,
        meta: {
          title: 'ProductServiceCategory',
          description: 'Categoria de Produtos e Serviços',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.product-service-category.dashboard.index'},
            {title: 'Categoria de Produtos e Serviços', name: null},
          ]
        },
      },
      {
        path: '/configuration/entry-launch',
        name: 'configuration.entry-launch.view',
        component: EntryLaunch,
        meta: {
          global_filter: true,
          title: 'Configuration',
          description: 'Entradas, Despesas e Custos',
          icon: 'fa fa-home',
          permission: 'entries.entry-launch.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.entry-launch.dashboard.index'},
            {title: 'Entradas, Despesas e Custos', name: null},
          ]
        },
      },
      {
        path: '/configuration/entry-launch/create/:entity_uuid',
        name: 'configuration.entry-launch.create',
        component: EntryLaunchCreate,
        meta: {
          title: 'Configuration',
          description: 'Lançamento de Despesas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Cadastro', name: 'configuration.entry-launch.dashboard.index'},
            {title: 'Novo Lançamento de Despesas', name: null},
          ]
        },
      },
      {
        path: '/configuration/entry-launch/edit/:id',
        name: 'configuration.entry-launch.edit',
        component: EntryLaunchEdit,
        meta: {
          title: 'Configuration',
          description: 'Lançamento de Despesas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Cadastro', name: 'configuration.entry-launch.dashboard.index'},
            {title: 'Editar Lançamento de Despesas', name: null},
          ]
        },
      },
      {
        path: '/configuration/entry-launch/external/:company_plant_buyer_id',
        name: 'configuration.entry-launch.external.view',
        component: EntryLaunchExternal,
        meta: {
          title: 'Configuration',
          description: 'Sefaz - Consulta',
          permission: 'entries.entry-launch.external.index.view',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.entry-launch.external.dashboard.index'},
            {title: 'Sefaz - Consulta', name: null},
          ]
        },
      },
      {
        path: '/configuration/entry-launch/external-create/:company_plant_id/:company_plant_buyer_id/:id',
        name: 'configuration.entry-launch.external.create',
        component: EntryLaunchExternalCreate,
        meta: {
          title: 'Configuration',
          description: 'Lançamento de Despesas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.entry-launch.external.dashboard.index'},
            {title: 'Novo Lançamento de Despesas', name: null},
          ]
        },
      },
      ...stockLocationRoute,
      {
        path: '/configuration/entry-launch/replication/:id',
        name: 'configuration.entry-launch.replication.create.view',
        component: EntryLaunchCreateByReplication,
        meta: {
          title: 'Configurações',
          description: 'Lançamento de Despesas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.entry-launch.replication.dashboard.index'},
            {title: 'Novo Lançamento de Despesas', name: null},
          ]
        },
      },
      {
        path: '/configuration/entry-launch/contract-proposal/:contract_proposal_id',
        name: 'configuration.entry-launch.contract-proposal.create.view',
        component: EntryLaunchCreateByContractProposal,
        meta: {
          title: 'Configurações',
          description: 'Lançamento de Despesas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.entry-launch.contract-proposal.dashboard.index'},
            {title: 'Novo Lançamento de Despesas', name: null},
          ]
        },
      },
      {
        path: '/configuration/entry-launch/entity/:entity_uuid',
        name: 'configuration.entry-launch.entity.create.view',
        component: EntryLaunchCreateByEntity,
        meta: {
          title: 'Configurações',
          description: 'Lançamento de Despesas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.entry-launch.entity.dashboard.index'},
            {title: 'Novo Lançamento de Despesas', name: null},
          ]
        },
      },
      {
        path: '/configuration/exit-launch',
        name: 'configuration.exit-launch.view',
        component: ExitLaunch,
        meta: {
          title: 'Configuration',
          description: 'Pedidos',
          icon: 'fa fa-home',
          permission: 'exit.exit-launch.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.exit-launch.dashboard.index'},
            {title: 'Pedidos', name: null},
          ]
        },
      },
      {
        path: '/configuration/exit-launch/create/:entity_uuid',
        name: 'configuration.exit-launch.create',
        component: ExitLaunchCreate,
        meta: {
          title: 'Configuration',
          description: 'Pedidos',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'configuration.exit-launch.create.dashboard.index'},
            {title: 'Novo Pedido', name: null},
          ]
        },
      },
      {
        path: '/configuration/exit-launch/edit/:id',
        name: 'configuration.exit-launch.edit',
        component: ExitLaunchEdit,
        meta: {
          title: 'Configuration',
          description: 'Pedidos',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Cadastro', name: 'configuration.exit-launch.dashboard.index'},
            {title: 'Editar Pedido', name: null},
          ]
        },
      },
      ...bankAccountRoute,
      ...IntegrationRoute,
    ]
  };

  export default configuration;
